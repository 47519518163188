import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '4px',
    minWidth: '200px',
    //width: 'max-content',
    width: '90%',
    height: 'max-content',
    boxShadow: '2px 5px 3px rgba(0, 0, 0, 0.4)',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width:'100%',
      marginLeft:0,
      marginRight:0,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '81px',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    '& .MuiTypography-root': {
      fontWeight: 400,
    },
  },
  heading: {
    //maxWidth: '250px',
  },
  content: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiTypography-root': {
      fontWeight: '300',
    },
    '& .MuiListItemText-primary': {
      fontSize: '1rem',
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(4),
    },
  },
}));

export default function InfoCard(props) {
  const classes = useStyles();

  return (
    <Box className={classes.container} style={props.style}>
      <Box className={classes.header}>
        <Box className={classes.heading}>
          <Typography variant="body2">{props.heading}</Typography>
        </Box>
      </Box>
      <Box className={classes.content}>{props.children}</Box>
    </Box>
  );
}
