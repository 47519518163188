import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';

// @material-ui/core components
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// components;
import Page from 'components/Page/Page';
import SEOTags from 'components/SEOTags/SEOTags';
import TextSection from 'components/TextSection/TextSection';
import InfoCard from 'components/InfoCard/InfoCard';

// assets
import ecoSystem from 'assets/img/recycletek-ecosystem.png';
import ecoPodXP from 'assets/img/eco-pod-xp.png';
import ecoPod from 'assets/img/eco-pod-alt.png';
import ecoMobile from 'assets/img/eco-mobile.png';
import ecoCount from 'assets/img/ecocount-machine.png';
import ecoCash from 'assets/img/eco-cash.png';
import sortMachine from 'assets/img/anker-andersen-sort.png';
import rocketIcon from 'assets/img/rocket-icon.png';
import transparencyIcon from 'assets/img/transparency-icon.png';
import lightbulbIcon from 'assets/img/lightbulb-icon.png';
import dataGraphic from 'assets/img/data-graphic.png';

import recycletekProductsBackofficeImage from 'assets/img/recycletek_products_backoffice.png'
import recycletekProductsCustomerPortalImage from 'assets/img/recycletek_products_portal.png'
import recycletekProductsUIImage from 'assets/img/recycletek_products_ui.png'

// spec-sheets
import datasheet_ecocount_3 from 'assets/datasheets/EcoCount 3 - Datasheet.pdf';
import datasheet_ecopod from 'assets/datasheets/EcoPod - Sales Sheet (Version B).pdf';
import datasheet_ecomobile_xp from 'assets/datasheets/EcoMobile XP - Datasheet.pdf';
import datasheet_ecocash from 'assets/datasheets/EcoCash Sales Sheet.pdf';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiTypography-body1': {
      fontWeight: 300,
    },
  },
  center: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  cta: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  cards: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  list: {
    maxWidth: '350px',
    minWidth: '300px',
  },
  card: {
    maxWidth: '600px',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover,&:focus': {
      textDecoration: 'underline',
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      height: 'auto',
      minWidth: '150px',
      width: '100%',
      clipPath: 'circle()',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  highlights: {
    width: '100%',
    justifyContent: 'space-evenly',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },
  highlight: {
    textAlign: 'center',
    minWidth: '200px',
    maxWidth: '200px',
    width: 'auto',
  },
}));

const Products = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const products = [
    {
      title: t('products.product.one.title'),
      image: ecoCount,
      points: [
        t('products.product.one.points.p1'),
        t('products.product.one.points.p2'),
        t('products.product.one.points.p3'),
      ],
      button_link: '/contact',
      button_text: 'Get a Quote',
      datasheet_link: datasheet_ecocount_3,
    },
    {
      title: t('products.product.two.title'),
      image: ecoMobile,
      points: [
        t('products.product.two.points.p1'),
        t('products.product.two.points.p2'),
        t('products.product.two.points.p3'),
        t('products.product.two.points.p4'),
      ],
      button_link: '/contact',
      button_text: 'Get a Quote',
      datasheet_link: datasheet_ecomobile_xp,
    },
    {
      title: t('products.product.three.title'),
      image: ecoPod,
      points: [
        t('products.product.three.points.p1'),
        t('products.product.three.points.p2'),
        t('products.product.three.points.p3'),
        t('products.product.three.points.p4'),
      ],
      button_link: '/contact',
      button_text: 'Get a Quote',
      datasheet_link: datasheet_ecopod,
    },
    {
      title: t('products.product.four.title'),
      image: ecoCash,
      points: [
        t('products.product.four.points.p1'),
        t('products.product.four.points.p2'),
        t('products.product.four.points.p3'),
        t('products.product.four.points.p4'),
      ],
      button_link: '/contact',
      button_text: 'Get a Quote',
      datasheet_link: datasheet_ecocash,
    },
    {
      title: t('products.product.five.title'),
      image: ecoPodXP,
      points: [
        t('products.product.five.points.p1'),
        t('products.product.five.points.p2'),
        t('products.product.five.points.p3'),
      ],
      button_link: '/contact',
      button_text: 'Get a Quote',
    },
    {
      title: t('products.product.six.title'),
      image: sortMachine,
      points: [
        t('products.product.six.points.p1'),
        t('products.product.six.points.p2'),
        t('products.product.six.points.p3'),
        t('products.product.six.points.p4'),
        t('products.product.six.points.p5'),
      ],
      button_link: '/contact',
      button_text: 'Get a Quote',
    },
  ];

  const highlights = [
    {
      text: t('products.highlights.one'),
      image: transparencyIcon,
    },
    {
      text: t('products.highlights.two'),
      image: rocketIcon,
    },
    {
      text: t('products.highlights.three'),
      image: lightbulbIcon,
    },
  ];

  const softwares = [
    {
      title: t('products.software.one.title'),
      image: recycletekProductsUIImage,
      points: [
        t('products.software.one.points.p1'),
        t('products.software.one.points.p2'),
        t('products.software.one.points.p3'),
        t('products.software.one.points.p4'),
      ],
    },
    {
      title: t('products.software.two.title'),
      image: recycletekProductsBackofficeImage,
      points: [
        t('products.software.two.points.p1'),
        t('products.software.two.points.p2'),
        t('products.software.two.points.p3'),
        t('products.software.two.points.p4'),
      ],
    },
    {
      title: t('products.software.three.title'),
      image: recycletekProductsCustomerPortalImage,
      points: [
        t('products.software.three.points.p1'),
        t('products.software.three.points.p2'),
        t('products.software.three.points.p3'),
        t('products.software.three.points.p4'),
      ],
    },
  ];

  return (
    <>
      <SEOTags
        title="Recycletek Products"
        description="View and learn about Recycletek products that can change the way you, or your business recycles."
      />
      <Page>
        <Container maxWidth="lg">
          <Typography variant="h2">{t('products.driveSuccess')}</Typography>
          <Typography variant="body2">
            <Trans>{t('products.driveSuccessParagraph')}</Trans>
          </Typography>

          <Typography variant="h2">{t('products.products')}</Typography>

          <Grid container>
            {products.map((product) => (
              <Grid item xs={12} md={6}>
                <InfoCard heading={product.title}>
                  <Grid container>
                    <Grid item xs={12} sm={8}>
                      <List dense>
                        {product.points.map((point) => (
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText variant="h4" primary={point} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <img
                        src={product.image}
                        alt="product image"
                        style={{ width: '100%' }}
                      />
                    </Grid>

                    {product.button_link !== undefined && (
                      <Grid item xs={6} style={{ marginTop: '20px' }}>
                        <a href={product.button_link}>
                          <Button>{product.button_text}</Button>
                        </a>
                      </Grid>
                    )}

                    {product.datasheet_link !== undefined && (
                      <Grid
                        item
                        xs={6}
                        style={{
                          textAlign: 'right',
                          alignContent: 'flex-end',
                          marginTop: '20px',
                        }}
                      >
                        <a
                          target="_blank"
                          href={product.datasheet_link}
                          className={classes.link}
                        >
                          {t('products.specSheet')}
                        </a>
                      </Grid>
                    )}
                  </Grid>
                </InfoCard>
              </Grid>
            ))}
          </Grid>

          <Grid container style={{ marginTop: '100px', marginBottom: '100px' }}>
            {highlights.map((highlight) => (
              <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                <img src={highlight.image} style={{ width: '30%' }} />
                <Typography variant="body2">{highlight.text}</Typography>
              </Grid>
            ))}
          </Grid>

          <Typography variant="h2">
            {t('products.softwareEcoSystem')}
          </Typography>

          <Grid container>
            {softwares.map((software) => (
              <Grid item xs={12} md={4}>
                <InfoCard heading={software.title}>
                  <Grid container>
                    <Grid item xs={12}>
                      <List dense>
                        {software.points.map((point) => (
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText variant="h4" primary={point} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                      <img
                        src={software.image}
                        alt="product image"
                        style={{ width: '90%', border:'1px solid gray' }}
                      />
                    </Grid>
                  </Grid>
                </InfoCard>
              </Grid>
            ))}
          </Grid>

          <Typography variant="h2">
            {t('products.improveOperations')}
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="body">
                <Trans>{t('products.improveOperationsParagraph')}</Trans>
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <img
                src={dataGraphic}
                alt="Measurable Results"
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default Products;
