import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(16),
  },
}));

export default function Section(props) {
  const classes = useStyles();

  return <Box className={classes.container}>{props.children}</Box>;
}
