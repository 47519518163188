import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactCodeInput from 'react-verification-code-input';

// mui
import {
  Dialog,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components
import { login } from 'redux/actions/auth';
import { transfer } from 'redux/actions/transactions';
import {
  PROCESSING_TRANSACTION,
  RESET_TWO_FACTOR_AUTH,
} from 'redux/actions/types';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  subtitle: {
    color: theme.palette.tertiary.main,
  },
  numberInput: {
    minWidth: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  checkbox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function TwoStepAuthModal({
  open,
  close,
  loginPage,
  withdrawal,
  message,
  emailOrPhone,
  password,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const twoFactorAuth = useSelector((state) => state.auth.twoFactorAuth);
  const processing = useSelector((state) => state.transactions.processing);
  const [token, setToken] = useState('');
  const classes = useStyles();

  const handleCancel = () => {
    dispatch({ type: RESET_TWO_FACTOR_AUTH });
    processing && dispatch({ type: PROCESSING_TRANSACTION, payload: false });
    close();
  };

  const handleSubmit = () => {
    if (loginPage) {
      dispatch(login(emailOrPhone, password, message, token));
    } else if (withdrawal) {
      dispatch(
        transfer(
          twoFactorAuth.withdrawal?.amount,
          twoFactorAuth.withdrawal?.handler,
          token,
        ),
      )
        .then(() => {
          close();
        })
        .catch(() => {
          console.error('Transaction error, could not authorize');
        });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        <Box className={classes.container}>
          <Typography color="primary" variant="h5">
            {t('twoStepAuth.header')}
          </Typography>
          <Typography className={classes.subtitle}>
            {twoFactorAuth.value
              ? t('twoStepAuth.sentCode', { location: twoFactorAuth.value })
              : t('twoStepAuth.tryAgain')}
          </Typography>
          <ReactCodeInput
            id="verification_code"
            name="verification code"
            type="number"
            fields={6}
            autoFocus={false}
            className={classes.numberInput}
            onChange={(e) => setToken(e)}
          />
          {/* <FormControlLabel
            className={classes.checkbox}
            control={<Checkbox />}
            label={t('twoStepAuth.trustedDevice')}
          /> */}
          <Box className={classes.buttonGroup}>
            <Button variant="contained" onClick={handleCancel}>
              {t('buttons.cancel')}
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              {t('buttons.submit')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
