import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
    },
  },
  toolbar: {
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '70px',
    },
  },
  main: {
    flexGrow: 1,
  },
}));

const Page = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Nav />
      <Toolbar className={classes.toolbar} />
      <div className={classes.main}>{props.children}</div>
      <Footer />
    </div>
  );
};

export default Page;

Page.propTypes = {
  children: PropTypes.node,
};
