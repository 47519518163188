import {
  ADD_TRANSACTION,
  ALERT_FAIL,
  ALERT_SUCCESS,
  SET_CUSTOMER,
  PROCESSING_TRANSACTION,
  SET_PAYMENTOPTIONS,
  SET_TRANSACTION_INFO,
  SET_DWOLLA,
  RESET_DWOLLA,
  SET_PAYMENT_DATA,
  TWO_FACTOR_AUTH,
  RESET_TWO_FACTOR_AUTH,
} from './types';
import axios from 'axios';
import { t } from 'i18next';
import { SERVER_URL } from '../../config.js';
import { configureConfigHeader } from 'utils/utils';
import {
  getAccountBalance,
  getLatestTransaction,
} from 'utils/formatTransactions';

export const addTransaction = (amount) => (dispatch) => {
  dispatch({
    type: PROCESSING_TRANSACTION,
  });
  setTimeout(
    (amount) => {
      dispatch({
        type: ADD_TRANSACTION,
        payload: amount,
      });
    },
    2000,
    amount,
  );
};

export const transfer = (amount, handler, token) => (dispatch, getState) => {
  dispatch({ type: PROCESSING_TRANSACTION, payload: true });
  const twoFactorAuth = getState().auth.twoFactorAuth;
  const config = configureConfigHeader(getState);
  const payout_amount = amount / 100;

  const body = JSON.stringify({ payout_amount, handler, otp: token });

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}/customer/payout`, body, config)
      .then((res) => {
        if (res.status === 202) {
          dispatch({
            type: TWO_FACTOR_AUTH,
            payload: {
              required: true,
              channel: res.data.channel,
              value: res.data.value,
              withdrawal: {
                handler,
                amount,
              },
            },
          });
        } else {
          twoFactorAuth.required && dispatch({ type: RESET_TWO_FACTOR_AUTH });
          dispatch(getCustomerBalance()).then(() => {
            dispatch({
              type: ALERT_SUCCESS,
              payload: t('common.transferSuccessful'),
            });
            dispatch({ type: PROCESSING_TRANSACTION, payload: false });
          });
        }
        resolve(res);
      })
      .catch((err) => {
        const message = t('common.pleaseTryAgain');
        console.error(err);
        dispatch({ type: ALERT_FAIL, payload: message });
        dispatch({ type: PROCESSING_TRANSACTION, payload: false });
        reject();
      });
  });
};

// Creates the dwolla customer and saves info on the dwolla server
// export const createDwollaUser = () => (dispatch, getState) => {
//   const config = configureConfigHeader(getState);

//   const body = JSON.stringify({ confirmed: true });
//   return axios.post(
//     `${SERVER_URL}/customer/create_dwolla_customer`,
//     body,
//     config
//   );
// };

// Generates a dwolla source token need to add a new funding source
// https://developers.dwolla.com/guides/dwolla-js/add-a-bank-account/create-token
// export const generateDwollaSourceToken = () => (dispatch, getState) => {
//   const config = configureConfigHeader(getState);
//   return axios.get(`${SERVER_URL}/customer/dwolla_source_token`, config);
// };

// Adds teh Dwolla funding url to the Evtek server
// export const addDwollaFundingUrl = (dwolla_funding) => (dispatch, getState) => {
//   const config = configureConfigHeader(getState);
//   const body = JSON.stringify({ dwolla_funding });
//   return axios.post(
//     `${SERVER_URL}/customer/dwolla_add_funding_source`,
//     body,
//     config
//   );
// };

// verifys Dwolla Funding
// export const verifyDwollaFunding = (verify_amount) => (dispatch, getState) => {
//   const config = configureConfigHeader(getState);
//   const body = JSON.stringify({ verify_amount });
//   return axios.post(`${SERVER_URL}/customer/dwolla_verify`, body, config);
// };

// Gets the dwolla funding source name
// export const dwollaFundingName = () => (dispatch, getState) => {
//   const config = configureConfigHeader(getState);
//   return axios.get(`${SERVER_URL}/customer/dwolla_funding_source_name`, config);
// };

// Dwolla route that checks if teh bank account as been verified
// export const checkDwollaFunding = () => (dispatch, getState) => {
//   const config = configureConfigHeader(getState);
//   return axios
//     .get(`${SERVER_URL}/customer/dwolla_check_verified`, config)
//     .then((res) => {
//       dispatch({
//         type: SET_DWOLLA,
//         payload: {
//           ...res.data,
//         },
//       });
//     })
//     .catch((err) => {
//       console.log(err);
//       dispatch({
//         type: RESET_DWOLLA,
//       });
//     });
// };

export const getTransactionHistory = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  return axios.get(`${SERVER_URL}/ledger/`, config);
};

export const getCustomerBalance = () => async (dispatch, getState) => {
  return Promise.all([dispatch(getTransactionHistory())])
    .then((res) => {
      const transactionHistory = res[0].data;
      const account_balance = getAccountBalance(transactionHistory);
      const latest_deposit = getLatestTransaction(transactionHistory);
      dispatch({
        type: SET_TRANSACTION_INFO,
        payload: {
          transactionHistory,
          account_balance,
          latest_deposit,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPaymentData = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  const getPaymentOptions = axios.get(
    `${SERVER_URL}/ledger/paymentoptions`,
    config,
  );
  const getCashoutOptions = axios.get(
    `${SERVER_URL}/ledger/cashoutoptions`,
    config,
  );

  return new Promise((resolve, reject) => {
    Promise.all([getPaymentOptions, getCashoutOptions])
      .then((res) => {
        const paymentOptions = res[0].data;
        const cashoutOptions = res[1].data;
        const options = {};

        paymentOptions.map((option) => {
          if (option.name === 'keep') options.keep = option;
          if (option.name === 'donate') options.donate = option;
        });

        cashoutOptions.map((option) => {
          const method = option.name;
          options.keep.methods = { ...options.keep.methods, [method]: option };
        });

        // TODO: eventually, transition transactions.paymentOptions to transactions.paymentData
        dispatch({
          type: SET_PAYMENTOPTIONS,
          payload: {
            paymentOptions,
          },
        });
        dispatch({
          type: SET_PAYMENT_DATA,
          payload: options,
        });

        resolve();
      })
      .catch((err) => {
        const errorInfo = err.response?.data?.msg;
        dispatch({
          type: ALERT_SUCCESS,
          payload:
            errorInfo || 'Error getting payment data, please try again later.',
        });
        reject(err);
      });
  });
};
