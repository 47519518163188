import {
  ALERT_FAIL,
  ALERT_SUCCESS,
  SET_VERIFYING,
  SET_CAPTCHA,
  SET_VERIFY,
  SET_PHONE,
  SET_EMAIL,
  DELETE_VERIFICATION,
  SET_ASSOCIATED_REDEMPTION_CENTER,
} from './types.js';

import axios from 'axios';
import { SERVER_URL } from '../../config.js';
import { configureConfigHeader } from 'utils/utils.js';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';

function returnDefaultConfig() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return config;
}

// gets captcha images and ids for phone and email verification
export const verificationRequest = (account_info) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let data;
    const email = account_info.email;
    const phoneNumber = account_info.phone_number;

    if (email && phoneNumber) {
      data = { email: email, phone_number: phoneNumber };
    } else if (email && !phoneNumber) {
      data = { email };
    } else if (!email && phoneNumber) {
      data = { phone_number: phoneNumber };
    }

    // reset the verification
    dispatch({ type: DELETE_VERIFICATION });
    const body = JSON.stringify(data);
    // set up the config
    const config = returnDefaultConfig();

    axios
      .post(`${SERVER_URL}/customer/verification_request`, body, config)
      .then((res) => {
        dispatch({ type: SET_CAPTCHA, payload: { ...res.data } });
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// checks captcha answer and sends codes to email and phone
export const verificationStart = (answer) => (dispatch, getState) => {
  dispatch({ type: SET_VERIFYING, payload: true });
  const id = getState().verification.id;
  // convert the info to a JSON string
  const body = JSON.stringify({ id, answer });
  // set up the config
  const config = returnDefaultConfig();
  return axios
    .post(`${SERVER_URL}/customer/verification_start`, body, config)
    .then((res) => {
      const { id_email_auth, id_phone_number_auth } = res.data;
      dispatch({ type: SET_VERIFYING, payload: false });
      dispatch({
        type: SET_VERIFY,
        payload: { id_email_auth, id_phone_number_auth },
      });
    });
};

// sends a verification code for logged in users
export const verificationLoggedIn = (values) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: SET_VERIFYING, payload: true });
    const config = configureConfigHeader(getState);
    const body = {
      email: values.email,
      phone_number: values.phone_number,
    };

    !values.phone_number && delete body.phone_number;
    !values.email && delete body.email;

    axios
      .post(`${SERVER_URL}/customer/verification_start_loggedin`, body, config)
      .then((res) => {
        dispatch({ type: SET_VERIFYING, payload: false });
        dispatch({
          type: SET_VERIFY,
          payload: res.data,
        });
        resolve();
      })
      .catch((err) => {
        const errMsg = err.response.data?.message ?? t('common.pleaseTryAgain');
        dispatch({
          type: ALERT_FAIL,
          payload: errMsg,
        });
        dispatch({ type: SET_VERIFYING, payload: false });
      });
  });
};

// checks email verification code submitted
export const emailVerification =
  (verification_code) => (dispatch, getState) => {
    dispatch({ type: SET_VERIFYING, payload: true });
    const id_email_auth = getState().verification.id_email_auth;
    const body = JSON.stringify({ id_email_auth, verification_code });
    const config = returnDefaultConfig();
    axios
      .post(`${SERVER_URL}/customer/verify_email_code`, body, config)
      .then((res) => {
        dispatch({ type: SET_EMAIL, payload: true });
        dispatch({ type: SET_VERIFYING, payload: false });
      })
      .catch((err) => {
        dispatch({
          type: ALERT_FAIL,
          payload: 'Incorrect code, please try again.',
        });
        dispatch({ type: SET_VERIFYING, payload: false });
      });
  };

// checks phone verification code submitted
export const phoneVerification =
  (verification_code) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: SET_VERIFYING, payload: true });
      const id_phone_number_auth = getState().verification.id_phone_number_auth;
      // convert the info to a JSON string
      const body = JSON.stringify({ id_phone_number_auth, verification_code });

      // set up the config
      const config = returnDefaultConfig();
      axios
        .post(`${SERVER_URL}/customer/verify_phone_number_code`, body, config)
        .then((res) => {
          dispatch({ type: SET_PHONE, payload: true });
          dispatch({ type: SET_VERIFYING, payload: false });
          resolve();
        })
        .catch((err) => {
          dispatch({
            type: ALERT_FAIL,
            payload: 'Incorrect code, please try again.',
          });
          dispatch({ type: SET_VERIFYING, payload: false });
        });
    });
  };

export const resetVerification = (email) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    resolve(dispatch({ type: DELETE_VERIFICATION }));
  });
};

export const getTenantList = () => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/tenant/shortcodes`, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

export const getTenantByShortcode = (code) => (dispatch, getState) => {
  const config = configureConfigHeader(getState);
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/tenant/get_by_shortcode/${code}`, config)
      .then((res) => {
        dispatch({
          type: SET_ASSOCIATED_REDEMPTION_CENTER,
          payload: code,
        });
        resolve(res.data.business_name);
      })
      .catch((err) => {
        reject();
      });
  });
};
 
