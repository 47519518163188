import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import { updateCustomer } from 'redux/actions/customer';

// Form Validation
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  Divider,
  FormHelperText,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
// @material-ui/icons
import Edit from '@material-ui/icons/Edit';

// Service
import Customer from 'api/CustomerService';

// Lib
import { states } from 'lib/formValues';

import PortalInputLabel from 'components/TextField/PortalInputLabel';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';
import CustomAutoComplete from 'components/CustomAutoComplete/CustomAutoComplete';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100%',
    borderRadius: 10,
    boxShadow: theme.shadows[25],
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0 0 0 ',
    },
  },
  form: {
    marginBottom: 0,
  },
  input: {
    marginBottom: theme.spacing(1),
    transition: 'all .3s ease-out',
    paddingLeft: '7px',
    width: 'auto',
    '&..MuiSelect-icon': {
      display: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      marginBottom: 0,
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  },
  sectionText: {
    paddingLeft: '7px',
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: '5px',
  },
  button: {
    cursor: 'pointer',
    display: 'inline-flex',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.aqua.main,
    padding: 0,
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  link: {
    fontWeight: 600,
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit',
    },
  },
}));

export default function AccountAddressForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);

  const handleOpen = () => {
    setEdit(false);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleCancel = () => {
    formik.handleReset();
    handleEdit();
  };

  const { street, housenumber, apt, city, state, zipcode } = props.validation;

  const validationSchema = yup.object({
    street,
    housenumber,
    apt,
    city,
    state,
    zipcode,
  });

  const handleClear = () => {
    formik.setFieldValue('street', '');
    formik.setFieldValue('housenumber', '');
    formik.setFieldValue('apt', '');
    formik.setFieldValue('city', '');
    formik.setFieldValue('state', '');
    formik.setFieldValue('zipcode', '');
  };

  const formik = useFormik({
    initialValues: {
      street: props.customer.street,
      housenumber: props.customer.housenumber,
      apt: props.customer.apt,
      city: props.customer.city,
      state: props.customer.state,
      zipcode: props.customer.zipcode,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      dispatch(updateCustomer(values));
      setEdit(true);
    },
  });

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardContent>
          <Box className={classes.header}>
            <Typography className={classes.title}>
              {t('account.form.address')}
            </Typography>
            {!edit && (
              <button className={classes.button} onClick={handleClear}>
                {t('buttons.clearAll')}
              </button>
            )}
          </Box>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.section}>
                {edit ? (
                  <div className={classes.sectionText}>
                    <Typography>{props.customer.street}</Typography>
                    <Typography>{props.customer.housenumber}</Typography>
                    <Typography>
                      {props.customer.apt && `Apt #: ${props.customer.apt}`}
                    </Typography>
                    <Typography>
                      {props.customer.city &&
                        `${props.customer.city}, ${props.customer.state}`}
                    </Typography>
                    <Typography>{props.customer.zipcode}</Typography>
                  </div>
                ) : (
                  <div>
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <PortalInputLabel
                          classes={{ root: classes.label }}
                          id="street"
                        >
                          {t('account.form.addressLineOne')}
                        </PortalInputLabel>
                        <PortalTextFieldNoShadow
                          fullWidth
                          type="text"
                          id="street"
                          name="street"
                          placeholder={t('account.form.addressLineOne')}
                          autoComplete="address-line1"
                          value={formik.values.street}
                          onChange={formik.handleChange}
                          disabled={edit}
                          error={
                            formik.touched.street &&
                            Boolean(formik.errors.street)
                          }
                          helperText={
                            formik.touched.street && formik.errors.street
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <PortalInputLabel
                          classes={{ root: classes.label }}
                          id="housenumber"
                        >
                          {t('account.form.addressLineTwo')}
                        </PortalInputLabel>
                        <PortalTextFieldNoShadow
                          fullWidth
                          type="text"
                          id="housenumber"
                          name="housenumber"
                          placeholder={t('account.form.addressLineOne')}
                          value={formik.values.housenumber}
                          onChange={formik.handleChange}
                          disabled={edit}
                        />
                      </Box>
                    </Grid>
                    <Box mb={2}>
                      <Grid item container xs={12}>
                        <Grid item xs={5}>
                          <Box mr={2}>
                            <PortalInputLabel
                              classes={{ root: classes.label }}
                              id="apt"
                            >
                              {t('account.form.aptUnit')}
                            </PortalInputLabel>
                            <PortalTextFieldNoShadow
                              fullWidth
                              type="text"
                              id="apt"
                              name="apt"
                              placeholder="#"
                              value={formik.values.apt}
                              onChange={formik.handleChange}
                              disabled={edit}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <PortalInputLabel
                            classes={{ root: classes.label }}
                            id="city"
                          >
                            {t('account.form.city')}
                          </PortalInputLabel>
                          <PortalTextFieldNoShadow
                            fullWidth
                            type="text"
                            id="city"
                            name="city"
                            placeholder={t('account.form.city')}
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            disabled={edit}
                            error={
                              formik.touched.city && Boolean(formik.errors.city)
                            }
                            helperText={
                              formik.touched.city && formik.errors.city
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid item container xs={12}>
                      <Grid item xs={6}>
                        <Box mr={2}>
                          <PortalInputLabel
                            classes={{ root: classes.label }}
                            id="state"
                          >
                            {t('account.form.state')}
                          </PortalInputLabel>
                          <CustomAutoComplete
                            boxShadow={'none'}
                            autoHighlight
                            disabled={edit}
                            id="state"
                            name="state"
                            list={states}
                            value={formik.values.state}
                            onChange={(e, value) => {
                              formik.setFieldValue('state', value);
                            }}
                            style={{ maxWidth: 95 }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <TextField {...params.inputProps} />
                              </div>
                            )}
                          />
                          <FormHelperText
                            error={
                              formik.touched.state &&
                              Boolean(formik.errors.state)
                            }
                          >
                            {formik.touched.state && formik.errors.state}
                          </FormHelperText>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <PortalInputLabel
                          classes={{ root: classes.label }}
                          id="zipcode"
                        >
                          {t('account.form.zipCode')}
                        </PortalInputLabel>
                        <PortalTextFieldNoShadow
                          fullWidth
                          type="text"
                          id="zipcode"
                          name="zipcode"
                          placeholder={t('account.form.zipCode')}
                          value={formik.values.zipcode}
                          onChange={formik.handleChange}
                          disabled={edit}
                          error={
                            formik.touched.zipcode &&
                            Boolean(formik.errors.zipcode)
                          }
                          helperText={
                            formik.touched.zipcode && formik.errors.zipcode
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box my={2}>
                  <Divider />
                </Box>
              </Grid>
              <Grid item container justify="flex-end">
                {edit ? (
                  <Box display="flex" alignItems="center">
                    <button className={classes.button} onClick={handleOpen}>
                      <Edit className={classes.icon} fontSize="small" />
                      <span>{t('account.form.updateAddress')}</span>
                    </button>
                  </Box>
                ) : null}
                {edit ? null : (
                  <div>
                    <button className={classes.button} onClick={handleCancel}>
                      {t('buttons.cancel')}
                    </button>
                    <button className={classes.button} type="submit">
                      {t('buttons.submit')}
                    </button>
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

AccountAddressForm.propTypes = {
  customer: PropTypes.shape({
    zipcode: PropTypes.string,
    city: PropTypes.string,
    apt: PropTypes.string,
    housenumber: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
  }),
  handleChange: PropTypes.func,
};
