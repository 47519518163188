import React from 'react';
import { useTranslation } from 'react-i18next';

// Material UI components
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// React Slideshow Image
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// assets
import pam from 'assets/img/memberReview.jpg';
import debbie from 'assets/img/debbieCustomerReview.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  imageBox: {
    margin: '0 3rem 0 0',
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 1rem 0',
    },
  },
  customerImage: {
    borderRadius: '50%',
    objectFit: 'cover',
    width: '200px',
    height: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      height: '150px',
    },
  },
  customerQuoteBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '0 0 0 3rem',
    minHeight: '200px',
    width: '60vw',
    maxWidth: '700px',
    borderLeft: `3px solid ${theme.palette.secondary.main}`,
    //color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem 0 1rem',
      borderLeft: 'none',
      width: '90vw',
      minHeight: 'auto',
      fontSize: '1rem',
      justifyContent: 'flex-start',
    },
  },
  customerQuote: {
    textAlign: 'justify',
    fontSize: '1rem',
    fontWeight: '500',
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  customerName: {
    padding: '1rem',
    fontWeight: 'bold',
    textAlign: 'left',
    display: 'block',
  },
}));

const customers = [
  {
    image: pam,
    alt: 'Pam',
    review: 'home.pamReview',
    name: '- Pam',
  },
  {
    image: debbie,
    alt: 'Steve West',
    review: 'home.debbieReview',
    name: '- Debbie',
  },
];
const CustomerReview = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Slide easing="ease" arrows={false}>
      {customers.map((customer, index) => (
        <div key={index} className={classes.root}>
          <div className={classes.imageBox}>
            <img
              src={customer.image}
              alt={customer.alt}
              className={classes.customerImage}
            />
          </div>
          <div className={classes.customerQuoteBox}>
            <Typography className={classes.customerQuote}>
              <q>{t(customer.review)}</q>
              <br />
              <span className={classes.customerName}>{customer.name}</span>
            </Typography>
          </div>
        </div>
      ))}
    </Slide>
  );
};

export default CustomerReview;