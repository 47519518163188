import { t } from 'i18next';

export const formatPaymentOption = (option) => {
  let text = '';

  switch (option) {
    case 'wallet':
      text = 'EcoWallet';
      break;
    case 'card':
      text = 'EcoCard';
      break;
    case 'cash':
      text = t('common.cash');
      break;
  }

  return text;
};
