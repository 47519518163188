import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '4px',
    width: 'max-content',
    boxShadow: '2px 5px 3px rgba(0, 0, 0, 0.4)',
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function CallToAction(props) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="body2">{props.text}</Typography>
      <a href={props.link}><Button>{props.buttonText}</Button></a>
    </Box>
  );
}
