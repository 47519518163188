import {
  LOGIN_SUCCESS,
  SET_CUSTOMER,
  LOGOUT,
  LOAD_USER_FROM_STORAGE,
  USER_LOADING,
  USER_LOADED,
  SET_LANGUAGE,
  SET_PREFERRED_LANGUAGE,
  TWO_FACTOR_AUTH,
  RESET_TWO_FACTOR_AUTH,
} from '../actions/types';

const initialState = {
  access_token: null,
  isAuthenticated: false,
  isLoading: false,
  customer: {},
  language: 'en',
  twoFactorAuth: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        access_token: action.payload.access_token,
        isAuthenticated: true,
        isLoading: false,
        customer: {
          ...action.payload.customer,
        },
      };
    case SET_CUSTOMER:
      return {
        ...state,
        isLoading: false,
        customer: {
          ...action.payload,
        },
      };
    case LOAD_USER_FROM_STORAGE:
      return {
        ...state,
        access_token: action.payload.user.access_token,
        isAuthenticated: true,
        isLoading: false,
        customer: {
          ...action.payload.user.customer,
        },
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_PREFERRED_LANGUAGE:
      return {
        ...state,
        customer: {
          ...state.customer,
          preferred_language: action.payload,
        },
      };
    case TWO_FACTOR_AUTH:
      return {
        ...state,
        twoFactorAuth: action.payload,
      };
    case RESET_TWO_FACTOR_AUTH:
      return {
        ...state,
        twoFactorAuth: {},
      };
    case LOGOUT:
      localStorage.removeItem('user');
      return {
        ...state,
        access_token: null,
        isAuthenticated: false,
        isLoading: false,
        customer: null,
        twoFactorAuth: {},
      };

    default:
      return state;
  }
}
