import React, { useState, useEffect } from "react";
import axios from "axios";

import { useTranslation, Trans } from 'react-i18next';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Hidden, Container, Box } from '@material-ui/core';

// components
import { Parallax } from 'react-parallax';

/* react timeline example start*/
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
/*
import FastfoodIcon from '@material-ui/icons-material/Fastfood';
import LaptopMacIcon from '@material-ui/icons-material/LaptopMac';
import HotelIcon from '@material-ui/icons-material/Hotel';
import RepeatIcon from '@material-ui/icons-material/Repeat';
*/
/* react timeline example end */

// assets
import world from 'assets/img/world.png';
import martin from 'assets/img/martin.jpg';
import yoni from 'assets/img/yoni.jpg';
import xavier from 'assets/img/xavier.png';
import tobi from 'assets/img/tobias_weis.jpg';
import megan from 'assets/img/megan.png';
import matt from 'assets/img/mattShapiro.png';
import jackie from 'assets/img/jackieEbner.png';
import jeffery from 'assets/img/jefferyASmith.jpg';
import matthew from 'assets/img/matthewFord.png';
import greg from 'assets/img/gregoryLanz.png';

import zwf from 'assets/img/recycletek-facility-bg.png';

import BlogCard from 'components/BlogCard/BlogCard';
import Page from 'components/Page/Page';

import { SERVER_URL } from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: theme.spacing(0, 8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  teaserText: {
    fontSize: '1.9rem',
    fontWeight: 300,
    lineHeight: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3125rem',
      lineHeight: 1.5,
    },
  },
  profile_image: {
    width: '95%',
    maxWidth: '523px',
    maxHeight: '950px',
    paddingRight: '15px',
  },
  founderName: {
    fontSize: '2rem',
    lineHeight: '2rem',
  },
  caption: {
    fontSize: '1.125rem',
    fontWeight: 300,
    lineHeight: '2rem',
    textAlign: 'justify',
  },
  founderTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '3rem',
  },
  container_right: {
    width: '100%',
    marginTop: '6rem',
    marginBottom: '5rem',
    paddingLeft: '12.5vw',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
      marginTop: theme.spacing(2),
    },
  },
  container_parallax: {
    width: '100vw',
    height: '600px',
    marginTop: '5rem',
    marginBottom: '5rem',
  },
  image: {
    width: '100%',
    maxWidth: '523px',
    maxHeight: '950px',
    float: 'right',
  },
  profile: {
    textAlign: 'center',
    maxWidth: '300px',
  },
  round_image: {
    width: '80%',
    minWidth: '100px',
    borderRadius: '50%',
    border: '5px solid #fff',
    boxShadow: '0 0 0 3px #aaa',
    margin: '50px 10px 20px 10px',
  },
  round_image_lg: {
    width: '100%',
    height: 'auto',
    minWidth: '100px',
    borderRadius: '50%',
    border: '5px solid #fff',
    boxShadow: '0 0 0 3px #aaa',
    margin: '50px 10px 20px 10px',
  },
  subContent: {
    fontSize: '1.5rem',
    fontWeight: 300,
    lineHeight: '3.2rem',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timeline_entry: {
    marginTop: '-2rem'
  }
}));

const About = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [postArr, setPostArr] = React.useState([]);

  useEffect(() => {
    const config = {headers:{'Content-Type':'application/json'}}
    const fetchPostInfo = async () =>{
        const response = await axios.get(`${SERVER_URL}/blog/posts`, config);
        response.data.reverse() //to get from new to last
        setPostArr(response.data.slice(0, 4));
    }
    fetchPostInfo();
  }, []);

  return (
    <Page>
      <div className={classes.main}>
        <Container maxWidth="lg">
          <Hidden mdDown>
            <Box style={{ position: 'absolute', right: '0' }}>
              <img
                className={classes.image}
                src={world}
                alt="Recycletek Green World"
              />
            </Box>
          </Hidden>

          <Grid
            container
            xs={12}
            md={12}
            lg={9}
            justify="left"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography variant="h2">
                {/* <Trans>{t('about.welcomeToRecycletek')}</Trans> */}
                Recycletek Mission
              </Typography>
              <Typography className={classes.teaserText}>
                {/* <Trans>{t('about.welcomeToRecycletekBody')}</Trans> */}
                Recycletek believes that deposit systems are the key to creating
                a waste free world and fueling the growth of the worlds circular
                economy. Our team is dedicated to building the most effective
                and efficient deposit container technology on earth.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2">
                {/* <Trans>{t('about.welcomeToRecycletek')}</Trans> */}
                News
              </Typography>
            </Grid>

            {postArr.length > 0 ? (
              postArr.map((post) => (
                <Grid item key={post.id} xs={12} sm={6} md={3}>
                  <BlogCard key={post.id} post={post} />
                </Grid>
              ))
            ) : (
              <Grid item>
                <Typography variant="body1">No Posts Found</Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <a
                href="/blogs"
                className={classes.link}
                style={{ float: 'right' }}
              >
                {' '}
                <i class="fa fa-newspaper"></i> View all Posts
              </a>
            </Grid>
          </Grid>
        </Container>

        <Parallax
          blur={0}
          strength={1000}
          bgImage={zwf}
          className={classes.container_parallax}
        ></Parallax>

        {/*
        <Container maxWidth="lg" className={classes.container}>
          <Grid container direction="row">
            <Grid item>
              <Typography color="primary" variant="h3">
                {t('about.ourMissionTitle')}
              </Typography>
              <Typography variant="body2">
                {t('about.ourMissionBody')}
              </Typography>
            </Grid>
          </Grid>
        </Container>*/}

        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="h2">{t('about.coFoundersTitle')}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <img
                className={classes.profile_image}
                src={martin}
                alt="Martin Naro, CEO of Evtek"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography className={classes.founderName}>
                {t('about.martinName')}
              </Typography>
              <Typography className={classes.founderTitle}>
                {t('about.martinPosition')}
              </Typography>
              <Typography className={classes.caption}>
                {t('about.martinDescription')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <br />
              <br />
            </Grid>

            <Grid item xs={12} sm={4}>
              <img
                className={classes.profile_image}
                src={yoni}
                alt="Yoni Saltzman, CTO of Evtek"
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <Typography className={classes.founderName}>
                {t('about.yoniName')}
              </Typography>
              <Typography className={classes.founderTitle}>
                {t('about.yoniPosition')}
              </Typography>
              <Typography className={classes.caption}>
                {t('about.yoniDescription')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <br />
              <br />
            </Grid>

            <Grid item xs={12} sm={4}>
              <img
                className={classes.profile_image}
                src={tobi}
                alt="Tobias Weis, C[AI&I]O of Evtek"
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <Typography className={classes.founderName}>
                {t('about.tobiName')}
              </Typography>
              <Typography className={classes.founderTitle}>
                {t('about.tobiPosition')}
              </Typography>
              <Typography className={classes.caption}>
                {t('about.tobiDescription')}
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container className={classes.container}>
          <Typography variant="h2">{t('about.recycletekTeamTitle')}</Typography>
          <Typography variant="body2">
            {t('about.recycletekTeamBody')}
          </Typography>
          <Grid container justify="center">
            <Grid item xs={4} sm={4} className={classes.profile}>
              <img className={classes.round_image} src={xavier} alt="Xavier" />
              <Typography variant="body2" color="primary">
                {t('about.xavierName')}
              </Typography>
              <Typography variant="caption">
                {t('about.xavierPosition')}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.profile}>
              <img className={classes.round_image} src={megan} alt="Megan" />
              <Typography variant="body2" color="primary">
                {t('about.meganName')}
              </Typography>
              <Typography variant="caption">
                {t('about.meganPosition')}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.profile}>
              <img className={classes.round_image} src={matt} alt="Matt" />
              <Typography variant="body2" color="primary">
                {t('about.mattName')}
              </Typography>
              <Typography variant="caption">
                {t('about.mattPosition')}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.profile}>
              <img className={classes.round_image} src={jackie} alt="Jackie" />
              <Typography variant="body2" color="primary">
                {t('about.jackieName')}
              </Typography>
              <Typography variant="caption">
                {t('about.jackiePosition')}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.profile}>
              <img
                className={classes.round_image}
                src={jeffery}
                alt="Jeff Smith"
              />
              <Typography variant="body2" color="primary">
                {t('about.jeffName')}
              </Typography>
              <Typography variant="caption">
                {t('about.jeffPosition')}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.profile}>
              <img
                className={classes.round_image}
                src={matthew}
                alt="Matthew Ford"
              />
              <Typography variant="body2" color="primary">
                {t('about.matthewName')}
              </Typography>
              <Typography variant="caption">
                {t('about.matthewPosition')}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.profile}>
              <img
                className={classes.round_image}
                src={greg}
                alt="Gregory Lanz"
              />
              <Typography variant="body2" color="primary">
                {t('about.gregoryName')}
              </Typography>
              <Typography variant="caption">
                {t('about.gregoryPosition')}
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h2">
              {t('about.recycletekMilestones')}
            </Typography>
          </Grid>

          <Timeline align="alternate" style={{ width: '100%' }}>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary"></TimelineDot>
                <TimelineConnector sx={{ bgColor: 'black' }} />
              </TimelineSeparator>

              <TimelineContent className={classes.timeline_entry}>
                <Typography variant="h6" component="h1">
                  2017
                </Typography>
                <Typography>{t('about.acceleratorWinner')}</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary"></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent className={classes.timeline_entry}>
                <Typography variant="h6" component="h1">
                  2018
                </Typography>
                <Typography>{t('about.companyIncorporated')}</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary"></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent className={classes.timeline_entry}>
                <Typography variant="h6" component="h1">
                  2019
                </Typography>
                <Typography>{t('about.marketResearch')}</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary"></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent className={classes.timeline_entry}>
                <Typography variant="h6" component="h1">
                  2020
                </Typography>
                <Typography>{t('about.ecoCountModelOne')}</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary"></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent className={classes.timeline_entry}>
                <Typography variant="h6" component="h1">
                  2021
                </Typography>
                <Typography>{t('about.ecoCountModelTwo')}</Typography>
                <Typography>{t('about.familyAndFriendsFunded')}</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary"></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent className={classes.timeline_entry}>
                <Typography variant="h6" component="h1">
                  2022
                </Typography>
                <Typography>{t('about.ecoCountModelTwoPointFive')}</Typography>
                <Typography>{t('about.calRecycleGrant')}</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary"></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent className={classes.timeline_entry}>
                <Typography variant="h6" component="h1">
                  2023
                </Typography>
                <Typography>{t('about.calRecyclePilotLaunch')}</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary"></TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>

              <TimelineContent className={classes.timeline_entry}>
                <Typography variant="h6" component="h1">
                  2024
                </Typography>
                <Typography>{t('about.ecoCountThreeManufacturing')}</Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Container>
      </div>
    </Page>
  );
};

export default About;

About.propTypes = {
  children: PropTypes.node,
};
