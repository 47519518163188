import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/auth.js';
import { Redirect, useLocation } from 'react-router-dom';

// Form validation libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Button,
  Box,
  Paper,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// components
import PortalTextField from 'components/TextField/PortalTextField';
import ResetStartForm from 'views/ResetPassword/Forms/ResetStartForm.js';
import TwoStepAuthModal from 'components/TwoStepAuth/TwoStepAuthModal';
import { RESET_TWO_FACTOR_AUTH } from 'redux/actions/types';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    padding: theme.spacing(3),
    backgroundColor: 'transparent',
    maxWidth: 550,
    boxShadow: 'none',
  },
  title: {
    fontWeight: 300,
    marginBottom: '.5rem',
  },
  subtitle: {
    color: theme.palette.tertiary.main,
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  passwordInput: {
    marginBottom: theme.spacing(1),
  },
  button: {
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.aqua.main,
      backgroundColor: 'transparent',
    },
  },
  adornment: {
    paddingRight: '5px',
  },
  btnContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

/* eslint-disable no-unused-vars */
export default function Login(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [storedCredentials, setStoredCredentials] = useState(null);
  const [password, setPassword] = useState('');
  const [resetOpen, setResetOpen] = useState(false);
  const [showTwoStepAuth, setShowTwoStepAuth] = useState(false);
  const [show, setShow] = useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShow((prev) => !prev);
  };

  const handleReset = () => {
    setResetOpen((prev) => !prev);
  };

  const validationSchema = yup.object({
    emailOrPhone: yup
      .string('Please enter your email or phone number')
      .required(t('login.form.emailRequired')),
    password: yup.string().required(t('login.form.pleaseEnterPassword')),
  });

  const formik = useFormik({
    initialValues: {
      emailOrPhone: emailOrPhone,
      password: password,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const message = t('login.form.welcomeToRecycletek');
      dispatch(login(values.emailOrPhone, values.password, message));
      setStoredCredentials({
        emailOrPhone: values.emailOrPhone,
        password: values.password,
      });
      setSubmitting(false);
      resetForm({});
    },
  });

  const closeTwoStepAuth = () => {
    dispatch({ type: RESET_TWO_FACTOR_AUTH });
    setShowTwoStepAuth(false);
  };

  if (auth.twoFactorAuth?.required) {
    !showTwoStepAuth && setShowTwoStepAuth(true);
  }

  if (auth.isAuthenticated) {
    // referrer is needed if the portal link is shared
    const referrer = location.state?.referrer || '/portal/dashboard';

    return <Redirect to={{ pathname: referrer, state: { referrer } }} />;
  }

  return (
    <Paper className={classes.loginContainer}>
      <Grid container>
        <Grid item component="div">
          <Box mb={4}>
            <Typography className={classes.title} color="primary" variant="h3">
              {t('login.login')}
            </Typography>
            <Typography className={classes.subtitle} variant="body1">
              {t('login.letsMakeSustainableAttainable')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <PortalTextField
              className={classes.input}
              id="emailOrPhone"
              name="emailOrPhone"
              placeholder={t('login.form.enterEmailOrPhone')}
              fullWidth
              value={formik.values.emailOrPhone}
              onChange={formik.handleChange}
              error={
                formik.touched.emailOrPhone &&
                Boolean(formik.errors.emailOrPhone)
              }
              helperText={
                formik.touched.emailOrPhone && formik.errors.emailOrPhone
              }
            />
            <PortalTextField
              className={classes.passwordInput}
              id="password"
              name="password"
              type={!show ? 'password' : 'text'}
              placeholder={t('login.form.password')}
              fullWidth
              autoComplete="off"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              endAdornment={
                <InputAdornment className={classes.adornment} position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <div className={classes.btnContainer}>
              <button
                className={classes.button}
                type="button"
                onClick={handleReset}
              >
                {t('login.forgotPassword')}
              </button>
            </div>
            <Box>
              <Button
                variant="contained"
                className={classes.loginButton}
                type="submit"
                simple="true"
                disabled={formik.isSubmitting}
              >
                {t('login.login')}
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
      <ResetStartForm open={resetOpen} handleClose={handleReset} />
      <TwoStepAuthModal
        loginPage
        message="Login successful, welcome to Recycletek!"
        open={showTwoStepAuth}
        emailOrPhone={storedCredentials?.emailOrPhone}
        password={storedCredentials?.password}
        close={closeTwoStepAuth}
      />
    </Paper>
  );
}
