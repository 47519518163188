import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Grid } from '@material-ui/core';

// components
import LocationsMap from 'components/Map/LocationsMap';
import ServiceInfo from 'components/ServiceInfo/ServiceInfo';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: '100%',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& span': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4),
    },
  },
}));

export default function RedemptionCentersSection() {
  const classes = useStyles();
  const { t } = useTranslation();
  const stateCoords = useSelector(
    (state) => state.customer.location.stateCoords,
  );
  const [defaultCenter, setDefaultCenter] = useState({});
  const [defaultZoom, setDefaultZoom] = useState(6);
  const [loadingLocation, setLoadingLocation] = useState(false);

  useEffect(() => {
    getDefaultCenter();
  }, [stateCoords]);

  const centerMap = (latitude, longitude, zoom) => {
    setDefaultCenter({
      lat: latitude,
      lng: longitude,
    });
    setDefaultZoom(zoom);
  };

  const getDefaultCenter = () => {
    centerMap(stateCoords.lat, stateCoords.lng, stateCoords.zoom);
  };

  const getCurrentLocation = () => {
    setLoadingLocation(true);

    const successCallback = async (position) => {
      centerMap(position.coords.latitude, position.coords.longitude, 6);
      setLoadingLocation(false);
    };

    const errorCallback = (err) => {
      console.log(err);
      setLoadingLocation(false);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  return (
  <>
      <Box className={classes.title}>
        <Typography variant="h3">
          {t('howItWorks.redemptionCenterLocations')}
        </Typography>
      </Box>
      <Grid container>
        <Grid className={classes.mapContainer} item sm={12} md={6}>
          <LocationsMap
            center={defaultCenter}
            zoom={defaultZoom}
            height="24rem"
            popoverSize="small"
          />
          <Typography variant="caption">
            {t('locations.holidayHours')}
          </Typography>
        </Grid>
        <Grid className={classes.text} item sm={12} md={5}>
          {/* <ServiceInfo textBody={t('howItWorks.useTheInteractiveMap')} /> */}
          <Typography variant="body2">
            {t('howItWorks.useTheInteractiveMap')}
          </Typography>

          {!loadingLocation && (
            <>
              <br />
              <Typography variant="body2">
                {t('howItWorks.click')}{' '}
                <span onClick={getCurrentLocation}>{t('howItWorks.here')}</span>
                {t('howItWorks.toUseCurrentLocation')}
              </Typography>
            </>
          )}
          {loadingLocation && (
            <ServiceInfo textBody={t('howItWorks.retrievingLocation')} />
          )}
        </Grid>
      </Grid>
      </>
  );
}
