import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { DateTime } from 'luxon';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Container,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// components
import Page from 'components/Page/Page';
import Section from 'components/Page/Section';
import OdometerNumber from 'views/Home/OdometerNumber';
import InfoCard from 'components/InfoCard/InfoCard';

import CustomerReview from './CustomerReviews.js'

// assets
import phoneGif from 'assets/img/phoneGifReduced.gif';
import ecoCount from 'assets/img/ecocount-machine.png';
import ecoCountOperator from 'assets/img/ecocount-operator.png';
import redemptionCenter from 'assets/img/redemption-center.jpg';
import dataGraphic from 'assets/img/data-graphic.png';
import ecoPod from 'assets/img/ecopod.png';

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    position: 'relative',
  },
  top: {
    backgroundColor: theme.palette.grey.main,
    height: '200px',
  },
  bottom: {
    height: '300px',
  },
  left: {
    '& img': {
      marginTop: theme.spacing(2),
      width: '100%',
      /*[theme.breakpoints.down('lg')]: {
        width: '80%',
      },*/
      //height: '370px',
      //width: '380px',
      backgroundColor: 'white',
      borderRadius: '50%',
    },
  },
  right: {
    marginTop: '76px',
    //marginLeft: '200px',
    //maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    '& h2': {
      fontSize: '78px',
      fontWeight: 400,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0',
      '& h2': { fontSize: '48px' },
    },
  },
  subHeading: {
    marginTop: theme.spacing(2),
  },
  recycling: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  reimagined: {
    display: 'flex',
    fontWeight: 500,
    //alignSelf: 'flex-end'
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    '& button:first-child': {
      marginRight: theme.spacing(6),
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  phone: {
    //height: '350px',
    //width: 'auto',
    width: '100%',
  },
  data: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    backgroundColor: theme.palette.grey.main,
  },
  number: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',
      marginBottom: '40px',
    },
  },
  center: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  card: {
    //width: '400px',
    //width: '100%',
    //width: 'max-content',
    minHeight: '480px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '550px',
    },
    position: 'relative',
  },
  list: {
    minWidth: '300px',
  },
  cardImg: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      width: '90%',
      height: 'auto',
      maxWidth: '300px',
      maxHeight: '200px',
      borderRadius: '14px',
    },
  },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '10px',
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [itemsRecycled, setItemsRecycled] = useState(0);
  const [metricTons, setMetricTons] = useState(0);

  const now = DateTime.local();
  const start = DateTime.fromISO('2021-03-01');

  const solutions = [
    {
      header: t('home.solutions.one.header'),
      image: ecoCountOperator,
      description: t('home.solutions.one.description'),
      points: [
        t('home.solutions.one.points.p1'),
        t('home.solutions.one.points.p2'),
        t('home.solutions.one.points.p2'),
      ],
      buttons: [
        {
          text: t('home.buttons.learnMore'),
          link: '/howitworks',
        },
        {
          text: t('home.buttons.signup'),
          link: '/signup',
        },
      ],
    },
    {
      header: t('home.solutions.two.header'),
      image: redemptionCenter,
      description: t('home.solutions.two.description'),
      points: [
        t('home.solutions.two.points.p1'),
        t('home.solutions.two.points.p2'),
        t('home.solutions.two.points.p3'),
      ],
      buttons: [
        {
          text: t('home.buttons.learnMore'),
          link: '/howitworks',
        },
        {
          text: t('home.buttons.products'),
          link: '/products',
        },
      ],
    },
    {
      header: t('home.solutions.three.header'),
      image: dataGraphic,
      description: t('home.solutions.three.description'),
      points: [
        t('home.solutions.three.points.p1'),
        t('home.solutions.three.points.p2'),
        t('home.solutions.three.points.p3'),
      ],
      buttons: [
        {
          text: t('home.buttons.learnMore'),
          link: '/howitworks',
        },
      ],
    },
    {
      header: t('home.solutions.four.header'),
      image: ecoPod,
      description: t('home.solutions.four.description'),
      points: [
        t('home.solutions.four.points.p1'),
        t('home.solutions.four.points.p2'),
        t('home.solutions.four.points.p3'),
      ],
      buttons: [
        {
          text: t('home.buttons.learnMore'),
          link: '/howitworks',
        },
        {
          text: t('home.buttons.products'),
          link: '/products',
        },
      ],
    },
  ];

  useEffect(() => {
    const weeks = Math.floor(now.diff(start, ['weeks']).values.weeks);
    const items = weeks * 21111 + 1560543;
    const carbon = (items * 3) / 453.6;
    setItemsRecycled(items);
    setMetricTons(carbon);
  }, []);

  return (
    <Page>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            xs={12}
            sm={5}
            md={6}
            className={classes.left}
            style={{ textAlign: 'center' }}
          >
            <img src={ecoCount} alt="Recycletek EcoCount machine" />
          </Grid>

          <Grid
            xs={12}
            sm={7}
            md={6} className={classes.right}>
            <Typography variant="h1">
              <span className={classes.recycling}>{t('home.recycling')}</span>
              <span className={classes.reimagined}>
                {t('home.reimagined')}<span style={{ fontSize: '1rem', paddingTop: '10px' }}>
                &#174;
              </span>
              </span>

            </Typography>

            <Typography className={classes.subHeading} variant="body2">
              <Trans>{t('home.recycletekProvidesAI')}</Trans>
            </Typography>
            <Box className={classes.buttonGroup}>
              <a href="/signup">
                <Button>{t('home.buttons.signup')}</Button>
              </a>
              <a href="/products">
                <Button>{t('home.buttons.products')}</Button>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Section>
        <Container maxWidth="lg" className={classes.row}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Typography variant="h2">
                {t('home.industryLeadingTech')}
              </Typography>
              <Typography variant="body2">
                <Trans>{t('home.industryLeadingTechParagraph')}</Trans>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <img className={classes.phone} src={phoneGif} alt="phone gif" />
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section>
        <Box className={classes.data}>
          <Grid container>
            <Grid xs={12} sm={4} className={classes.number}>
              <Typography>{t('home.lessThan')}</Typography>
              <OdometerNumber number={30} />
              <Typography>{t('home.secondsForDropOffs')}</Typography>
            </Grid>

            <Grid xs={12} sm={4} className={classes.number}>
              <OdometerNumber number={itemsRecycled} />{' '}
              <Typography>{t('home.itemsRecycled')}</Typography>
            </Grid>

            <Grid xs={12} sm={4} className={classes.number}>
              <OdometerNumber number={metricTons} />
              <Typography>{t('home.carbonSaved')}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Section>

      <Section>
        <Container maxWidth="lg">
          <Typography variant="h2">{t('home.whatCanRecycletekDo')}</Typography>

          <Grid container>
            {solutions.map((solution) => (
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: 'inline-flex', justifyContent: 'center' }}
              >
                <InfoCard heading={solution.header}>
                  <Box className={classes.card}>
                    <Typography>{solution.description}</Typography>

                    <List dense>
                      {solution.points.map((point) => (
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText variant="h4" primary={point} />
                        </ListItem>
                      ))}
                    </List>

                    <Box className={classes.cardImg}>
                      <img src={solution.image} alt="Recycletek graphic" />
                    </Box>

                    <Box className={classes.footer}>
                      <Grid container>
                        {solution.buttons.map((button) => (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ textAlign: 'center', marginTop: '10px' }}
                          >
                            <a href={button.link}>
                              <Button>{button.text}</Button>
                            </a>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </InfoCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>

    <Section>
        <CustomerReview />
    </Section>

    </Page>
  );
};

export default Home;
