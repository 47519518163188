import React, { useState, useEffect } from 'react';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
import { Waypoint } from 'react-waypoint'; // detects on-screen elements

// @mui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  value: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '7px',
    width: '100%',
    alignItems: 'center',
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
    },
  },
}));

export default function OdometerNumber({ number }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const calculateTotal = () => {
    setValue(number);
  };

  const removeTotal = () => {
    setValue(0);
  };

  return (
    <Box className={classes.value}>
      <Waypoint onEnter={calculateTotal} onLeave={removeTotal}>
        <Typography variant="h4">
          <Odometer
            value={value}
            format="(,ddd)"
            theme="default"
            duration={7000}
          />
        </Typography>
      </Waypoint>
    </Box>
  );
}
