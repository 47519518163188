import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';

// @material-ui/core components
import {
  Box,
  Grid,
  Container,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
//import TabPanel from '@material-ui/lab/TabPanel';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PropTypes from 'prop-types';

// components;
import Page from 'components/Page/Page';
import TitleSection from './Section/TitleSection';
import GeoLocator from 'components/GeoLocator/GeoLocator';
import SEOTags from 'components/SEOTags/SEOTags';
import TextSection from 'components/TextSection/TextSection';
import InfoCard from 'components/InfoCard/InfoCard';
import RedemptionCenterLocations from 'views/HowItWorks/Section/RedemptionCentersSection';
import CallToAction from 'components/CallToAction/CallToAction';
import Section from 'components/Page/Section';

// assets
import truckGif from 'assets/img/truckGifReduced.gif';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import signupIcon from 'assets/img/signup-icon.png';
import containerIcon from 'assets/img/container-icon.png';
import getPaidIcon from 'assets/img/get-paid-icon.png';
import ecoSystem from 'assets/img/recycletek-ecosystem.png';
import ecoPod from 'assets/img/ecopod.png';
import recyclingTogether from 'assets/img/recycling-together.png';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiTypography-body1': {
      fontWeight: 300,
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
    },
    '& .MuiTypography-root': {
      fontWeight: 300,
    },
  },
  truck: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(6),
    height: '350px',
    width: 'auto',
  },
  card: {
    maxWidth: '265px',
    minHeight: '120px',
    height: 'max-content',
  },
  footer: {
    marginTop: theme.spacing(4),
  },
  steps: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    '& .MuiTypography-body2': {
      fontWeight: 400,
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    maxWidth: '300px',
    textAlign: 'center',
    '& img': {
      height: '100px',
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
  services: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  service: {
    //marginRight: theme.spacing(4),
    //width: '300px',
    width: '90%',
    //marginBottom: theme.spacing(4),
    '& .MuiTypography-body2': {
      fontWeight: 400,
      marginBottom: theme.spacing(1),
    },
  },
  ecoSystem: {
    height: '300px',
    width: 'auto',
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ecoPod: {
    marginLeft: theme.spacing(4),
    height: '300px',
    width: 'auto',
  },
  community: {
    height: '200px',
    width: 'auto',
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(6),
  },
  cta: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  sticky: {
    position: '-webkit-sticky',
    position: '-moz-sticky',
    position: '-ms-sticky',
    position: 'sticky',
    top: '100px',
    backgroundColor: 'white',
    zIndex: 10,
  },
  anchor_section: {
    scrollMarginTop: '100px',
  },
}));

const HowItWorks = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const centers = [
    {
      title: t('how.center.one.title'),
      points: [
        t('how.center.one.points.p1'),
        t('how.center.one.points.p2'),
        t('how.center.one.points.p3'),
        t('how.center.one.points.p4'),
      ],
    },
    {
      title: t('how.center.two.title'),
      points: [
        t('how.center.two.points.p1'),
        t('how.center.two.points.p2'),
        t('how.center.two.points.p3'),
      ],
    },
    {
      title: t('how.center.three.title'),
      points: [
        t('how.center.three.points.p1'),
        t('how.center.three.points.p2'),
        t('how.center.three.points.p3'),
      ],
    },
    {
      title: t('how.center.four.title'),
      points: [t('how.center.four.points.p1'), t('how.center.four.points.p2')],
    },
  ];

  const retailers = [
    {
      title: t('how.retailer.one.title'),
      description: t('how.retailer.one.description'),
    },
    {
      title: t('how.retailer.two.title'),
      description: t('how.retailer.two.description'),
    },
    {
      title: t('how.retailer.three.title'),
      description: t('how.retailer.three.description'),
    },
    {
      title: t('how.retailer.four.title'),
      description: t('how.retailer.four.description'),
    },
  ];

  return (
    <>
      <SEOTags
        title="How Reverse Vending Machine Works - Recycletek"
        description="We have reinvented recycling by radically improving the system of collection, identification, and redemption through our reverse vending machine technology."
      />
      <GeoLocator />
      <Page>
        <Container maxWidth="lg" className={classes.container}>
          <Box className={classes.main}>
            <Box className={classes.fullWidthContainer}>
              <TitleSection />
            </Box>
          </Box>

          <Section>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography variant="h2">
                  {t('how.beneficialForAll')}
                </Typography>

                <Typography variant="body2">
                  <Trans>{t('how.beneficialForAllParagraph')}</Trans>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <img
                  className={classes.truck}
                  src={truckGif}
                  alt="recycling truck"
                />
              </Grid>
            </Grid>
          </Section>

          <Grid container>
            <Grid item xs={12} md={3}>
              <div
                className={classes.sticky}
                style={{
                  width: '90%',
                  margin: 0,
                  marginRight: '20px',
                  minWidth: 0,
                  padding: 0,
                }}
              >
                <InfoCard heading={t('how.onThisPage')}>
                  <List dense>
                    <ListItem>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon fontSize="small" />
                      </ListItemIcon>
                      <a href="#customers" className={classes.link}>
                        {t('how.recyclingReturnCustomers')}
                      </a>
                    </ListItem>

                    <hr />

                    <ListItem>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon fontSize="small" />
                      </ListItemIcon>
                      <a href="#redemption-centers" className={classes.link}>
                        {t('how.recyclingReturnCustomers')}
                      </a>
                    </ListItem>

                    <hr />

                    <ListItem>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon fontSize="small" />
                      </ListItemIcon>
                      <a href="#retailers" className={classes.link}>
                        {t('how.retailers')}
                      </a>
                    </ListItem>

                    <hr />

                    <ListItem>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon fontSize="small" />
                      </ListItemIcon>
                      <a href="#municipalities" className={classes.link}>
                        {t('how.sustainabilityManagers')}
                      </a>
                    </ListItem>
                  </List>
                </InfoCard>
              </div>
            </Grid>

            <Grid item xs={12} md={9}>
              <div id="customers" className={classes.anchor_section}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h2">
                      {t('how.solutionsForReturnCustomers')}
                    </Typography>
                  </Grid>

                  <Typography variant="h3">
                    {t('how.gettingPaidIsSimple')}
                  </Typography>

                  <Box className={classes.steps}>
                    <Box className={classes.step}>
                      <img src={signupIcon} alt="sign up icon" />
                      <Typography variant="body2">{t('how.signUp')}</Typography>
                      <Typography>{t('how.freeAccount')}</Typography>
                    </Box>

                    <Box className={classes.step}>
                      <img src={containerIcon} alt="bottle icon" />
                      <Typography variant="body2">
                        {t('how.bringYourContainers')}
                      </Typography>
                      <Typography>{t('how.walkInOrDropOff')}</Typography>
                    </Box>

                    <Box className={classes.step}>
                      <img src={getPaidIcon} alt="dollar sign icon" />
                      <Typography variant="body2">
                        {t('how.getPaid')}
                      </Typography>
                      <Typography>{t('how.payoutOptions')}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Section>
                  <RedemptionCenterLocations />
                </Section>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      {t('how.recycletekServices')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ padding: '20px' }}>
                    <Typography variant="body2">
                      {t('how.walkInCashOut')}
                    </Typography>
                    <Typography>{t('how.walkInCashOutParagraph')}</Typography>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ padding: '20px' }}>
                    <Typography variant="body2">{t('how.rewards')}</Typography>
                    <Typography>{t('how.rewardsParagraph')}</Typography>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ padding: '20px' }}>
                    <Typography variant="body2">
                      {t('how.pickupService')}
                    </Typography>
                    <Typography>{t('how.pickupServiceParagraph')}</Typography>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ padding: '20px' }}>
                    <Typography variant="body2">{t('how.bagDrop')}</Typography>
                    <Typography>{t('how.bagDropParagraph')}</Typography>
                  </Grid>
                </Grid>
                <Box className={classes.cta}>
                  <CallToAction
                    text={t('how.startRecycling')}
                    buttonText={t('how.signUp')}
                    link="/signup"
                  />
                </Box>
              </div>

              <div id="redemption-centers" className={classes.anchor_section}>
                <Box className={classes.center}>
                  <Typography variant="h2">
                    {t('how.solutionsForRedemptionCenters')}
                  </Typography>
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      {t('how.boostEfficiency')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="body2">
                      <Trans>{t('how.boostEfficiencyParagraph')}</Trans>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <img
                      style={{ width: '100%' }}
                      src={ecoSystem}
                      alt="Recycletek flowchart"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  {centers.map((solution) => (
                    <Grid item xs={12} md={3}>
                      <InfoCard heading={solution.title}>
                        <Box className={classes.card}>
                          <List dense>
                            {solution.points.map((point) => (
                              <ListItem>
                                <ListItemIcon>
                                  <CheckCircleIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText variant="h4" primary={point} />
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </InfoCard>
                    </Grid>
                  ))}
                </Grid>
                <Box className={classes.cta}>
                  <CallToAction
                    text={t('how.productCatalog')}
                    buttonText="Products"
                    link="/products"
                  />
                </Box>
              </div>

              <div id="retailers" className={classes.anchor_section}>
                <Typography variant="h2">
                  {t('how.solutionsForRetailers')}
                </Typography>
                <Grid container>
                  <Typography variant="h3">
                    {t('how.unparalledExperience')}
                  </Typography>
                  <Grid item xs={12} md={8}>
                    <Typography variant="body2">
                      Lorem ipsum dolor sit amet. Aut voluptatum quidem non
                      officiis galisum qui omnis expedita non consequuntur
                      asperiores id nesciunt internos in rerum ullam.
                      <br />
                      <br />
                      Hic autem ipsa et possimus voluptatibus ea quibusdam
                      recusandae ea. Aut voluptatum quidem non officiis galisum
                      qui omnis expedita non consequuntur asperiores id nesciunt
                      internos in rerum ullam. Hic autem ipsa et possimus
                      voluptatibus ea quibusdam recusandae ea
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <img
                      style={{ width: '100%' }}
                      src={ecoPod}
                      alt="Recycletek EcoPod"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  {retailers.map((solution) => (
                    <Grid item xs={12} md={3}>
                      <InfoCard heading={solution.title}>
                        <Box className={classes.card}>
                          <Typography>{solution.description}</Typography>
                        </Box>
                      </InfoCard>
                    </Grid>
                  ))}
                </Grid>
                <Box className={classes.cta}>
                  <CallToAction
                    text={t('how.productCatalog')}
                    buttonText={t('how.products')}
                    link="/products"
                  />
                </Box>
              </div>

              <div id="municipalities" className={classes.anchor_section}>
                <Typography variant="h2">
                  {t('how.solutionsForMunicipalities')}
                </Typography>
                <Grid container>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h3">
                      {t('how.minimizeWaste')}
                    </Typography>
                    <Typography variant="body2">
                      {t('how.minimizeWasteParagraph')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <img
                      style={{ width: '100%' }}
                      src={recyclingTogether}
                      alt="Recycling together in a group"
                    />
                  </Grid>
                </Grid>
                <Box className={classes.cta}>
                  <CallToAction
                    text={t('how.productCatalog')}
                    buttonText={t('how.products')}
                    link="/products"
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default HowItWorks;

{
  /* <Container maxWidth="lg" className={classes.container}>
{services.curbside ? (
  <StepsToRecycleSection />
) : (
  getDropOffSection()
)}
</Container>
<div className={classes.fullWidthContainer}>
<ParallaxSection />
</div>
<Container maxWidth="lg" className={classes.fullWidthContainer}>
{services.curbside ? (
  getDropOffSection()
) : (
  <StepsToRecycleSection />
)}
</Container>
<Container maxWidth="lg" className={classes.container}>
<BusinessCollectionSection />
</Container>
<Container maxWidth="lg" className={classes.container}>
<EcoSupplySection />
</Container>
<Container
maxWidth="lg"
className={`${classes.container} ${classes.metricsSectionContainer}`}
>
<MetricsSection />
</Container>
<Container maxWidth="lg" className={classes.container}>
<RewardsSection />
</Container> */
}
