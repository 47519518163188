import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';

// @material-ui/core components
import { Toolbar, Box, Container, Typography, Grid } from '@material-ui/core';

// components
import Customer from 'api/CustomerService';
import NameForm from './Sections/NameForm';
import EmailForm from './Sections/EmailForm';
import AccountAddressForm from './Sections/AccountAddressForm';
import PasswordForm from './Sections/PasswordForm';
import PhoneNumberForm from './Sections/PhoneNumberForm';
import BusinessNameForm from './Sections/BusinessNameForm';
import PayPalEmailForm from './Sections/PayPalEmailForm';
import SecurityForm from './Sections/SecurityForm';
import LanguageForm from './Sections/LanguageForm';
import PaymentPreferenceForm from './Sections/PaymentPreferenceForm';
import { getCharities } from 'redux/actions/charities';
import { SET_VERIFY } from 'redux/actions/types';

// utils
import {formatPaymentOption} from 'utils/formatPaymentOption';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(20),
  },
  button: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.aqua.main,
    padding: 0,
    fontSize: theme.typography.pxToRem(14),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  info: {
    display: 'flex',
    justifyContent: 'start',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  settingContainer: {
    marginBottom: theme.spacing(2),
  },
  settingText: {
    display: 'inline',
    marginRight: theme.spacing(3),
  },
}));

const Account = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.auth.customer);
  const paymentData = useSelector((state) => state.transactions.paymentData);
  const charities = useSelector((state) => state.charities.list);
  const [preferredPaymentOption, setPreferredPaymentOption] = useState('');
  const [open, setOpen] = useState({
    name: false,
    email: false,
    password: false,
    business: false,
    language: false,
    payment: false,
  });

  useEffect(() => {
    getPreferredPaymentOption();
  }, [customer, paymentData]);

  const handleClickOpen = (form) => {
    if (form === 'email') {
      dispatch({
        type: SET_VERIFY,
        payload: { id_email_auth: '' },
      });
    }
    setOpen((prev) => ({
      ...prev,
      [form]: true,
    }));
  };

  const handleClose = (form) => {
    setOpen((prev) => ({
      ...prev,
      [form]: false,
    }));
  };

  const validationSchema = {
    email: yup
      .string(t('account.form.enterEmail'))
      .email(t('account.form.enterValidEmailFormat'))
      .required(t('account.form.emailRequired'))
      .test(
        'Unique Email',
        t('account.form.emailAlreadyInUse'),
        async (value) => {
          if (value && value.includes('@')) {
            try {
              let res = await Customer.isUniqueEmail(value);
              if (res.status === 200) {
                return true;
              }
            } catch (err) {
              return false;
            }
          }
        },
      ),
    password: yup
      .string()
      .min(6)
      .required(t('account.form.currentPasswordRequired')),
    newPassword: yup.string().required(t('account.form.pleaseEnterPassword')),
    confirmPassword: yup
      .string()
      .required(t('account.form.pleaseConfirmPassword'))
      .oneOf(
        [yup.ref('newPassword'), null],
        t('account.form.passwordsMustMatch'),
      ),
    first_name: yup.string().required(t('account.form.pleaseEnterFirstName')),
    business_name: yup
      .string()
      .required(t('account.form.pleaseEnterBusinessName')),
    last_name: yup.string().required(t('account.form.pleaseEnterLastName')),
    street: yup.string().required(t('account.form.pleaseEnterStreet')),
    city: yup.string().required(t('account.form.pleaseEnterCity')),
    state: yup
      .string()
      .required(t('account.form.pleaseSelectState'))
      .nullable(),
    phone_number: yup
      .string()
      .required(t('account.form.pleaseEnterPhone'))
      .min(7, t('account.form.mustBeSevenDigits')),
    zipcode: yup
      .string()
      .required(t('account.form.pleaseEnterZipcode'))
      .matches(/^[0-9]+$/, t('account.form.mustBeOnlyDigits'))
      .min(5, t('account.form.mustBeFiveDigits'))
      .max(5, t('account.form.mustBeFiveDigits'))
      .test('servicable', t('account.form.outOfServiceArea'), async (value) => {
        // makes sure the api call only runs when the value is not equal to the original
        if (value === customer.zipcode) return true;
        if (value && value.length === 5) {
          try {
            let res = await Customer.isServiceable(value);
            if (res.status === 200) {
              return true;
            }
          } catch (err) {
            return false;
          }
        }
      }),
    type: yup.number(),
    businessName: yup.string().when('type', {
      is: 1,
      then: yup.string().required(t('account.form.pleaseEnterBusinessName')),
      otherwise: yup.string().nullable(),
    }),
    paypal_email: yup
      .string(t('account.form.enterPayPalAccountEmail'))
      .email(t('account.form.enterValidEmailFormat'))
      .required(t('account.form.emailRequired')),
  };

  const getPreferredPaymentOption = () => {
    const keep = customer.preferred_payment_option === 1;
    const keepId = customer.preferred_cashout_option;
    const donate = customer.preferred_payment_option === 2;
    const donateId = customer.preferred_donee_id;
    const keepMethodsData = paymentData.keep?.methods;
    let paymentName = '';

    if (keep && keepMethodsData) {
      Object.keys(keepMethodsData).map((payment) => {
        if (keepMethodsData[payment].id === keepId) {
          paymentName = payment;
        }
      });
    } else if (donate && donateId) {
      charities.length <= 0 && dispatch(getCharities());
      charities.length > 0 &&
        charities.map((charity) => {
          if (charity.id == donateId) {
            paymentName = `${t('account.form.donate')} ${
              charity.business_name
            }`;
          }
        });
    }

    setPreferredPaymentOption(paymentName);
  };

  const getPaymentText = () => {
    const text = formatPaymentOption(preferredPaymentOption);

    return text;
  };

  const Setting = (props) => {
    return (
      <Box className={classes.settingContainer}>
        <Typography
          className={classes.settingText}
          variant={props.variant}
          color={props.color}
        >
          {props.text}
        </Typography>
        {props.button && (
          <button
            className={classes.button}
            onClick={() => handleClickOpen(props.buttonId)}
          >
            {props.buttonText}
          </button>
        )}
        {props.cash && (
          <Box className={classes.cash}>
            <Typography variant="caption">
              {t('account.form.availableAtSelectLocations')}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <div className={classes.main}>
      <Toolbar />
      <Container maxWidth="lg" className={classes.container}>
        <Box mb={10}>
          <Setting
            text={`${customer.first_name} ${customer.last_name}`}
            variant="h4"
            color="primary"
            button
            buttonId="name"
            buttonText={
              customer.first_name || customer.last_name
                ? t('account.changeName')
                : t('account.addName')
            }
          />
          {customer.type === 1 && (
            <Setting
              text={customer.business_name}
              variant="h5"
              button
              buttonId="business"
              buttonText={t('account.updateBusinessName')}
            />
          )}
          <Setting
            text={customer.email}
            button
            buttonId="email"
            buttonText={t('account.changeEmail')}
          />
          <Setting
            text={`${t('account.form.languagePreference')} ${
              customer.preferred_language || 'en'
            }`}
            button
            buttonId="language"
            buttonText={t('account.form.changeLanguage')}
          />

          <Setting
            text={`${t('account.form.paymentPreference')} ${getPaymentText()}`}
            button
            buttonId="payment"
            cash={preferredPaymentOption === 'cash'}
            buttonText={t('account.form.changePayment')}
          />
        </Box>
        <Grid container spacing={4} className={classes.info}>
          <Grid item xs={10} sm={6} md={4}>
            <AccountAddressForm
              validation={validationSchema}
              customer={customer}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={4}>
            <PhoneNumberForm
              validation={validationSchema}
              customer={customer}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={4}>
            <PayPalEmailForm
              validation={validationSchema}
              customer={customer}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={4}>
            <SecurityForm customer={customer} />
          </Grid>
        </Grid>
      </Container>
      <NameForm
        open={open.name}
        validation={validationSchema}
        handleClose={handleClose}
      />
      <EmailForm
        open={open.email}
        validation={validationSchema}
        handleClose={handleClose}
      />
      <LanguageForm open={open.language} handleClose={handleClose} />
      <PaymentPreferenceForm open={open.payment} handleClose={handleClose} />
      <PasswordForm
        open={open.password}
        validation={validationSchema}
        handleClose={handleClose}
      />
      <BusinessNameForm
        open={open.business}
        validation={validationSchema}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Account;
