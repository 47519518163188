import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';

// components/assets
import caution from 'assets/img/caution.png';
import { updateCustomer } from 'redux/actions/customer';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    boxShadow: theme.shadows[25],
    paddingBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0 0 0 ',
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  settings: {
    paddingLeft: '7px',
    width: '100%',
  },
  setting: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    width: '100%',
    '& p': {
      marginRight: theme.spacing(2),
    },
  },
  adjustment: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    cursor: 'pointer',
    display: 'inline-flex',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.aqua.main,
    padding: 0,
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
  },
  disclaimer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      height: '18px',
      width: 'auto',
      marginTop: '-3px',
      marginRight: '2px',
    },
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  footer: {
    height: 'max-content',
    margin: '0px',
    padding: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function SecurityForm({ customer }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // const [edit, setEdit] = useState(true);
  const [edit, setEdit] = useState(true);
  const [device, setDevice] = useState(customer.two_factor_preferred_channel);
  const [auth, setAuth] = useState({
    login: false,
    withdrawal: false,
  });

  useEffect(() => {
    setAuth({
      login: customer.two_factor_auth_on_login,
      withdrawal: customer.two_factor_auth_on_withdrawal,
    });
  }, []);

  const handleEdit = () => {
    setEdit((prevState) => !prevState);
  };

  const handleToggle = (event) => {
    setAuth({ ...auth, [event.target.name]: event.target.checked });
  };

  const handleSelect = (event) => {
    setDevice(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      updateCustomer({
        two_factor_auth_on_login: auth.login,
        two_factor_auth_on_withdrawal: auth.withdrawal,
        two_factor_preferred_channel: device,
      }),
    );
    handleEdit();
  };

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title}>
            {t('twoStepAuth.authAndSecurity')}
          </Typography>
          {edit && (
            <Box className={classes.settings}>
              <Box className={classes.setting}>
                <Typography>{t('twoStepAuth.login')}</Typography>
                <Typography>
                  {customer.two_factor_auth_on_login
                    ? t('twoStepAuth.enabled')
                    : t('twoStepAuth.disabled')}
                </Typography>
              </Box>
              <Box className={classes.setting}>
                <Typography>{t('twoStepAuth.withdrawal')}</Typography>
                <Typography>
                  {customer.two_factor_auth_on_withdrawal
                    ? t('twoStepAuth.enabled')
                    : t('twoStepAuth.disabled')}
                </Typography>
              </Box>
              <Box className={classes.setting}>
                <Typography>{t('twoStepAuth.sendCodeBy')}</Typography>
                <Box>
                  <Typography>
                    {customer.two_factor_preferred_channel}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {!edit && (
            <Box className={classes.settings}>
              <Box className={classes.adjustment}>
                <Typography>{t('twoStepAuth.login')}</Typography>
                <Box className={classes.switch}>
                  <Typography>{t('twoStepAuth.off')}</Typography>
                  <Switch
                    checked={auth.login}
                    onChange={handleToggle}
                    color="primary"
                    name="login"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography>{t('twoStepAuth.on')}</Typography>
                </Box>
              </Box>
              <Box className={classes.adjustment}>
                <Typography>{t('twoStepAuth.withdrawals')}</Typography>
                <Box className={classes.switch}>
                  <Typography>{t('twoStepAuth.off')}</Typography>
                  <Switch
                    checked={auth.withdrawal}
                    onChange={handleToggle}
                    color="primary"
                    name="withdrawal"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography>{t('twoStepAuth.on')}</Typography>
                </Box>
              </Box>
              <Box className={classes.adjustment}>
                <Typography>{t('twoStepAuth.sendCodeBy')}</Typography>
                <Select
                  value={device}
                  onChange={handleSelect}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem disabled={!customer.email} value={'email'}>
                    {t('twoStepAuth.email')}
                  </MenuItem>
                  <MenuItem disabled={!customer.phone_number} value={'sms'}>
                    {t('twoStepAuth.sms')}
                  </MenuItem>
                </Select>
              </Box>
              <Box className={classes.disclaimer}>
                <Box className={classes.warning}>
                  <img src={caution} alt="caution sign" />
                  <Typography variant="caption">
                    {t('twoStepAuth.warning')}
                  </Typography>
                </Box>
                <Typography variant="caption">
                  {t('twoStepAuth.disclaimer')}
                </Typography>
              </Box>
            </Box>
          )}
          <Grid item xs={12}>
            <Box my={2}>
              <Divider />
            </Box>
          </Grid>
          <Box className={classes.footer}>
            {edit && (
              <>
                <button className={classes.button} onClick={handleEdit}>
                  <Edit className={classes.icon} fontSize="small" />
                  <span>{t('twoStepAuth.update')}</span>
                </button>
              </>
            )}
            {!edit && (
              <>
                <button className={classes.button} onClick={handleEdit}>
                  {t('buttons.cancel')}
                </button>
                <button className={classes.button} onClick={handleSubmit}>
                  {t('buttons.submit')}
                </button>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
