/*eslint-disable*/
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// react components for routing our app without refresh
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'redux/actions/auth';
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { List, ListItem, Hidden } from '@material-ui/core';
import AccountMenu from './AccountMenu';

// components
import LanguageSelect from 'components/LanguageSelect/LanguageSelect';

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
    paddingLeft: 0,
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      flexDirection: 'column',
    },
  },
  listItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0 1rem',
    lineHeight: '.9rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      lineHeight: '0',
      float: 'none',
      '&:not(:last-child)': {
        borderRight: '0',
      },
    },
  },
  navLink: {
    fontWeight: 300,
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    border: 'none',
    padding: '0',
    lineHeight: '0',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'inherit',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main,
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.5s ease-in-out 0s',
    },
    '&:hover::after': {
      visibility: 'visible',
      transform: 'scaleX(1)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      padding: '0.9375rem',
      '& > span:first-child': {
        justifyContent: 'flex-start',
      },
      '&:hover::after': {
        visibility: 'hidden',
      },
      '&:hover': {
        color: '#b3b2b2',
      },
    },
  },
  navLinkBold: {
    fontWeight: 500,
    fontSize: '1rem',
    color: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    border: 'none',
    padding: '0',
    lineHeight: '0',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'inherit',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main,
      visibility: 'hidden',
      transform: 'scaleX(0)',
      transition: 'all 0.5s ease-in-out 0s',
    },
    '&:hover::after': {
      visibility: 'visible',
      transform: 'scaleX(1)',
    },
    [theme.breakpoints.down('sm')]: {
      fontWeight: 300,
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      padding: '0.9375rem',
      '& > span:first-child': {
        justifyContent: 'flex-start',
      },
      '&:hover::after': {
        visibility: 'hidden',
      },
      '&:hover': {
        color: '#b3b2b2',
      },
    },
  },
  active: {
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 0,
      width: '100%',
      height: '2px',
      display: 'block',
      background: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      '&:before': {
        visibility: 'hidden',
      },
    },
  },
}));

export default function UpdateHeaderLink(props) {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    if (matches) {
      return;
    } else {
      props.handleDrawerToggle();
    }
  };
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <NavLink
          exact
          to="/howitworks"
          activeClassName={classes.active}
          className={classes.navLink}
          onClick={handleClick}
        >
          {t('nav.howItWorks')}
        </NavLink>
      </ListItem>
      {/*<ListItem className={classes.listItem}>
        <NavLink
          exact
          to="/Technology"
          activeClassName={classes.active}
          className={classes.navLink}
          onClick={handleClick}
        >
          {t('nav.technology')}
        </NavLink>
      </ListItem>*/}
      <ListItem className={classes.listItem}>
        <NavLink
          exact
          to="/products"
          activeClassName={classes.active}
          className={classes.navLink}
          onClick={handleClick}
        >
          Products
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          exact
          to="/about"
          activeClassName={classes.active}
          className={classes.navLink}
          onClick={handleClick}
        >
          {t('nav.about')}
        </NavLink>
      </ListItem>
      {auth.language === 'en' && (
        <ListItem className={classes.listItem}>
          <NavLink
            exact
            to="/blogs"
            activeClassName={classes.active}
            className={classes.navLink}
            onClick={handleClick}
          >
            {t('nav.blog')}
          </NavLink>
        </ListItem>
      )}
      {!auth.isAuthenticated ? (
        <>
          <ListItem className={classes.listItem}>
            <div data-cy="login-link">
              <NavLink
                exact
                to="/login"
                activeClassName={classes.active}
                className={classes.navLinkBold}
                onClick={handleClick}
              >
                {t('buttons.logIn')}
              </NavLink>
            </div>
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink
              exact
              to="/signup"
              activeClassName={classes.active}
              className={classes.navLinkBold}
              onClick={handleClick}
            >
              {t('buttons.signUp')}
            </NavLink>
          </ListItem>
        </>
      ) : (
        <React.Fragment>
          <ListItem className={classes.listItem}>
            <NavLink
              exact
              to="/portal/dashboard"
              activeClassName={classes.active}
              className={classes.navLink}
              onClick={handleClick}
            >
              {t('nav.dashboard')}
            </NavLink>
          </ListItem>
          <Hidden smDown implementation="css">
            <ListItem className={classes.listItem}>
              <AccountMenu />
            </ListItem>
          </Hidden>
        </React.Fragment>
      )}
      <Hidden smDown implementation="css">
        <LanguageSelect />
      </Hidden>
    </List>
  );
}
