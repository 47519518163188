import React, { Suspense, useEffect } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { loadUserFromStorage, logout } from 'redux/actions/auth';
import LoginSignUp from 'views/LoginSignUp/LoginSignUp.js';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute.js';
import CustomAlert from 'components/CustomAlerts/CustomAlert.js';
import SEOTags from 'components/SEOTags/SEOTags';

// Pages
import Home from 'views/Home/Home';
import About from 'views/About/About';
import Contact from 'views/Contact/Contact';
import Products from 'views/Products/Products';
import GivingBack from 'views/GivingBack/GivingBack';
import Blogs from 'views/Blog/Blogs';
import Blog from 'views/Blog/Blog';
import InvestorRelations from 'views/InvestorRelations/InvestorRelations';
import HowItWorks from 'views/HowItWorks/HowItWorks';
import Technology from 'views/Technology/Technology';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import ResetPassword from 'views/ResetPassword/ResetPassword';

// material ui theme
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import theme from './themes/theme';
import MemberPortalTheme from './themes/memberPortalTheme';
import TermsAndConditions from 'views/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from 'views/PrivacyPolicy/PrivacyPolicy';

function App() {
  const dispatch = useDispatch();
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  useEffect(() => {
    dispatch(loadUserFromStorage());
  }, []);

  // intercept all requests and look for a 401 (unauthorized)
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const storage = JSON.parse(localStorage.getItem('user'));
      const isAuthenticated = storage.user.isAuthenticated;

      if (error.response?.status === 401 && !isAuthenticated) {
        console.log('[Interceptor] YOU ARE UNAUTHORIZED!');
        dispatch(logout());
        return Promise.reject(error);
      }
    },
  );

  return (
    <Suspense fallback="loading">
      <MuiThemeProvider theme={theme}>
        <SEOTags
          title="Simple Recycling Solutions For Bottle Redemption - Recycletek"
          description="Our simple recycling technology keeps plastic, metal, and glass out of landfills, while depositing monetary rewards directly into your personal account."
        />
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <CustomAlert />
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/howitworks" component={HowItWorks} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/technology" component={Technology} />
            <Route exact path="/about" component={About} />
            <Route exact path="/giving-back" component={GivingBack} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/blogs" component={Blogs} />
            <Route exact path="/blog/:id" component={Blog} />
            <Route exact path="/investor" component={InvestorRelations} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          </Switch>
          <MuiThemeProvider theme={MemberPortalTheme}>
            <Switch>
              <Route path={['/login', '/signup']} component={LoginSignUp} />
              <Route exact path="/reset_pwd" component={ResetPassword} />
              <Route exact path="/portal/:page?" component={PrivateRoute} />
            </Switch>
          </MuiThemeProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </Suspense>
  );
}

export default App;
