import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { registerCustomer } from 'redux/actions/customer.js';
import {
  verificationRequest,
  resetVerification,
} from 'redux/actions/verification';

import {
  ALERT_SUCCESS,
  ALERT_FAIL,
  SET_VERIFYING,
  DELETE_VERIFICATION,
} from 'redux/actions/types.js';

// mui components
import {
  Paper,
  Backdrop,
  CircularProgress,
  Typography,
  Box,
} from '@material-ui/core';

// Api
import Customer from 'api/CustomerService';

// Components
import AccountForm from './AccountForm';
import AddressForm from './AddressForm';
import ReviewForm from './ReviewForm';
import ServiceAreaFrom from './ServiceAreaForm';
import WaitlistForm from './WaitlistForm';
import SignUpStepper from '../Section/SignUpStepper';
import Verification from '../Section/Verification';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: 'transparent',
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(3),
  },
  captchaContainer: {
    display: 'flex',
    width: '250px',
    height: 'auto',
    backgroundColor: 'red',
    flexWrap: 'wrap',
  },
  captchaImageContainer: {
    flex: 1,
  },
  captchaImage: {
    width: '100%',
    height: 'auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
  },
  spinnerText: {
    color: theme.palette.primary.contrastText,
  },
}));

const steps = [
  'signup.steps.setupCredentials',
  'signup.steps.accountDetails',
  'signup.steps.reviewInfo',
  'signup.steps.verifyAccount',
];

const initialUserCustomer = {
  email: '',
  password: '',
  confirmPassword: '',
  type: 0,
  first_name: '',
  last_name: '',
  phone_number: '',
  street: '',
  housenumber: '',
  state: '',
  city: '',
  apt: '',
  zipcode: '',
  business_name: '',
  from_tenant_shortcode: '',
  preferred_cashout_option: 2,
  preferred_payment_option: 1,
};

export default function CreateAccountWindow() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const customer = useSelector((state) => state.customer);
  const [activeStep, setActiveStep] = React.useState(0);
  const [serviceable, setServiceable] = React.useState(false);
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const [newEmail, setNewEmail] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  // Captcha
  const captcha = useSelector((state) => state.verification);

  // Password Checks
  const [length, setLength] = React.useState(false);
  const [uppercase, setUppercase] = React.useState(false);
  const [lowercase, setLowercase] = React.useState(false);
  const [number, setNumber] = React.useState(false);
  const [specialCharacter, setSpecialCharacter] = React.useState(false);
  const [match, setMatch] = React.useState(false);
  const [waitlistZip, setWaitlistZip] = React.useState('');

  // new customer account info
  // todo: put in redux
  const [newCustomer, setNewCustomer] = React.useState({
    ...initialUserCustomer,
  });

  const validationSchema = {
    email: yup
      .string(t('signup.form.enterEmail'))
      .email(t('signup.form.enterValidEmailFormat'))
      .required(t('signup.form.emailRequired'))
      .test(
        'Unique Email',
        t('signup.form.emailAlreadyInUse'),
        async (value) => {
          if (value === newEmail) return true;
          if (value && value.includes('@')) {
            try {
              const res = await Customer.isUniqueEmail(value);
              if (res.status === 200) {
                setNewEmail(value);
                return true;
              }
            } catch (err) {
              dispatch({
                type: ALERT_FAIL,
                payload: t('signup.form.emailAlreadyInUse'),
              });
              setNewEmail(value);
              return false;
            }
          }
        },
      ),
    password: yup
      .string()
      .required(t('signup.form.pleaseEnterPassword'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\])(?=.{8,})/,
        t('signup.form.passwordValidationRequirements'),
      )
      .test('checks', 'Checks password strength', async (value) => {
        // regex tests for each check
        const upper = /(?=.*[A-Z])/;
        const lower = /(?=.*[a-z])/;
        const number = /(?=.*[0-9])/;
        const special = /(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\])/;
        const check =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\])(?=.{8,})/;
        if (value && value.length >= 8) {
          setLength(true);
        } else {
          setLength(false);
        }
        if (value && upper.test(value)) {
          setUppercase(true);
        } else {
          setUppercase(false);
        }
        if (value && lower.test(value)) {
          setLowercase(true);
        } else {
          setLowercase(false);
        }
        if (value && number.test(value)) {
          setNumber(true);
        } else {
          setNumber(false);
        }
        if (value && special.test(value)) {
          setSpecialCharacter(true);
        } else {
          setSpecialCharacter(false);
        }
        if (value && check.test(value)) {
          return true;
        } else {
          return false;
        }
      }),
    confirmPassword: yup
      .string()
      .required(t('signup.form.passwordsMustMatch'))
      .when('password', {
        is: (password) => password && password.length > 0,
        then: yup
          .string()
          .test(
            'matches',
            'Passwords do not match',
            async (value, allValues) => {
              const password = allValues.parent.password;
              if (password === value) {
                setMatch(true);
                return true;
              } else {
                setMatch(false);
                return false;
              }
            },
          ),
      }),
    first_name: yup
      .string()
      .required(t('signup.form.pleaseEnterFirstName'))
      .matches(/^[a-zA-Z\s-]*$/, t('signup.form.mustBeLettersOnly')),
    last_name: yup
      .string()
      .matches(/^[a-zA-Z\s-]*$/, t('signup.form.mustBeLettersOnly'))
      .required(t('signup.form.pleaseEnterLastName')),
    street: yup.string().required(t('signup.form.pleaseEnterStreet')),
    city: yup.string().required(t('signup.form.pleaseEnterCity')),
    state: yup.string().required(t('signup.form.pleaseSelectState')).nullable(),
    zipcode: yup
      .string()
      .required(t('signup.form.pleaseEnterZipcode'))
      .matches(/^[0-9]+$/, t('signup.form.mustBeOnlyDigits'))
      .min(5, t('signup.form.mustBeFiveDigits'))
      .max(5, t('signup.form.mustBeFiveDigits'))
      .test('servicable', 'Zip code is out of service area', async (value) => {
        if (value && value.length === 5) {
          try {
            const res = await Customer.isServiceable(value);
            if (res.status === 200) {
              return true;
            }
          } catch (err) {
            return false;
          }
        } else {
          return false;
        }
      }),
    type: yup.number(),
    business_name: yup.string().when('type', {
      is: 1,
      then: yup.string().required(t('signup.form.pleaseEnterBusinessName')),
      otherwise: yup.string().nullable(),
    }),
  };

  // scrolls user to top of screen when the step is changed
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const handleChangeNewCustomer = (values) => {
    setNewCustomer((prev) => {
      return { ...prev, ...values };
    });
  };

  // opens and closes model for user to sign up to be notified
  const handleNotify = (open, zipcode) => {
    setWaitlistZip(zipcode);
    setNotifyOpen((prev) => !prev);
  };

  // changes flag on if the user is serviceable
  const handleServiceable = () => {
    setServiceable((prev) => !prev);
  };

  // changes what step of signup user is on
  // TODO: default to plus but if passed prev go back a step
  const handleChangeActiveStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  // disables submit button
  const handleSubmit = () => {
    setSubmitting((prev) => !prev);
  };

  // checks if customer's zip code is in a serviceable area
  const handleServiceAreaCheck = (values) => {
    const { zipcode } = values;
    Customer.isServiceable(zipcode)
      .then((res) => {
        dispatch({
          type: ALERT_SUCCESS,
          payload: t('signup.form.youAreLocatedInOurServiceArea'),
        });
        handleChangeNewCustomer({ zipcode });
        setServiceable(true);
      })
      .catch((error) => {
        console.log(error);
        setNotifyOpen(true);
      });
  };

  // submits the new customer
  const handleSubmitNewCustomer = () => {
    // add the tenant shortcode to the customer object
    newCustomer.from_tenant_shortcode = customer.redemptionCenter;

    !newCustomer.email && delete newCustomer.email;
    !newCustomer.phone_number && delete newCustomer.phone_number;

    if (newCustomer.type === 0) {
      delete newCustomer.business_name;
    }
    dispatch(registerCustomer(newCustomer));
  };

  // initiates verification process and gets captcha
  // handles error if phone number or email is already in use
  const handleInitiateVerification = () => {
    const { email, phone_number } = newCustomer;
    dispatch({ type: DELETE_VERIFICATION });
    dispatch({ type: SET_VERIFYING, payload: true });
    dispatch(verificationRequest({ email, phone_number }))
      .then((res) => {
        handleChangeActiveStep();
        dispatch({ type: SET_VERIFYING, payload: false });
      })
      .catch((err) => {
        const msg = t('signup.form.errorCreating');
        dispatch({ type: SET_VERIFYING, payload: false });
        dispatch({
          type: ALERT_FAIL,
          payload:
            err.response.status === 400 ? `${err.response.data.msg}` : msg,
        });
      });
  };

  // restarts verification process
  const handleResend = () => {
    dispatch(resetVerification()).then(() => {
      dispatch({ type: SET_VERIFYING, payload: true });
      setActiveStep((prev) => prev - 1);
      handleInitiateVerification();
    });
  };

  // resets sign in process
  const handleBack = () => {
    if (activeStep === 0) {
      setServiceable(false);
      setActiveStep(0);
      setNewCustomer({ ...initialUserCustomer });
    } else if (activeStep === 1) {
      handleRestVer();
      setNewCustomer((prev) => {
        return { ...prev, password: '', confirmPassword: '' };
      });
      setActiveStep((prev) => prev - 1);
    } else {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleRestVer = () => {
    setLength(false);
    setUppercase(false);
    setLowercase(false);
    setNumber(false);
    setSpecialCharacter(false);
    setMatch(false);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AccountForm
            validation={validationSchema}
            userInfo={newCustomer}
            handleBack={handleBack}
            handleChange={handleChangeNewCustomer}
            handleChangeActiveStep={handleChangeActiveStep}
            length={length}
            uppercase={uppercase}
            lowercase={lowercase}
            number={number}
            specialCharacter={specialCharacter}
            match={match}
          />
        );
      case 1:
        return (
          <AddressForm
            validation={validationSchema}
            userInfo={newCustomer}
            handleChange={handleChangeNewCustomer}
            handleBack={handleBack}
            handleChangeActiveStep={handleChangeActiveStep}
          />
        );
      case 2:
        return (
          <ReviewForm
            validation={validationSchema}
            userInfo={newCustomer}
            handleChange={handleChangeNewCustomer}
            handleBack={handleBack}
            handleChangeActiveStep={handleChangeActiveStep}
            submitting={submitting}
            handleSubmit={handleSubmit}
            handleInitiateVerification={handleInitiateVerification}
          />
        );
      case 3:
        return (
          <Verification
            handleBack={handleBack}
            newCustomer={newCustomer}
            handleSubmitNewCustomer={handleSubmitNewCustomer}
            handleInitiateVerification={handleInitiateVerification}
            handleResend={handleResend}
            userInfo={newCustomer}
          />
        );
      default:
        return (
          <ServiceAreaFrom
            userInfo={newCustomer}
            handleServiceAreaCheck={handleServiceAreaCheck}
            handleChangeActiveStep={handleChangeActiveStep}
          />
        );
    }
  };

  // if user is authenticated proceed go to dashboard
  if (auth.isAuthenticated) {
    return <Redirect to="/portal/dashboard" />;
  }
  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={0}>
        {serviceable ? (
          <React.Fragment>
            <SignUpStepper activeStep={activeStep} steps={steps} />
            {getStepContent(activeStep)}
          </React.Fragment>
        ) : null}
        {serviceable ? null : (
          <ServiceAreaFrom
            handleServiceable={handleServiceable}
            handleChangeNewCustomer={handleChangeNewCustomer}
            handleNotify={handleNotify}
          />
        )}
        {/* Dialog for waitlist sign up */}
        <WaitlistForm
          zipcode={waitlistZip}
          open={notifyOpen}
          handleClose={handleNotify}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={captcha.verifying}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="inherit" />
          <Typography align="center" className={classes.spinnerText}>
            <Trans>{t('signup.form.doNotRefresh')}</Trans>
          </Typography>
        </Box>
      </Backdrop>
    </React.Fragment>
  );
}
