import React from 'react';
import clsx from 'clsx';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Box, Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '650px',
    margin: theme.spacing(6),
    '& h5': {
      fontWeight: 400,
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft:'0px',
      marginRight:'0px',
    },
  },
  fullWidth: {
    maxWidth: 'unset',
    minWidth: '100%',
    width: '100%',
  },
}));

export default function TextSection(props) {
  const classes = useStyles();

  const classNames = clsx({
    [classes.container]: true,
    [classes.fullWidth]: props.fullWidth,
  });

  return (
    <Box className={classNames}>
      <Typography variant="h5">{props.heading}</Typography>
      {props.children}
    </Box>
  );
}
