import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ALERT_FAIL,
  ALERT_SUCCESS,
  PROCESSING_TRANSACTION,
  RESET_TWO_FACTOR_AUTH,
} from 'redux/actions/types';
import { transfer, getCustomerBalance } from 'redux/actions/transactions';

// nodejs library to set properties for components
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Typography,
  DialogContent,
  CircularProgress,
  Box,
} from '@material-ui/core';

// Components
import PayPalSignup from '../Forms/PayPalSignup';
import PayPalTransferForm from '../Forms/PayPalTransferForm';
import PayPalConfirm from '../Forms/PayPalConfirm';
import PayPalError from './PayPalError';
import PayPalSuccess from './PayPalSuccess';
import TwoStepAuthModal from 'components/TwoStepAuth/TwoStepAuthModal';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    minWidth: '350px',
  },
  title: {
    paddingBottom: '0',
    fontWeight: 300,
  },
  bold: {
    fontWeight: 400,
  },
  spinner: {
    minHeight: '300px',
  },
}));

const PayPalTransfer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paypal_email = useSelector((state) => state.auth.customer.paypal_email);
  const { account_balance, processing } = useSelector(
    (state) => state.transactions,
  );
  const twoFactorAuth = useSelector((state) => state.auth.twoFactorAuth);
  const [activeStep, setActiveStep] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [remaining, setRemaining] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);

  const handleAmount = (value) => {
    const amount_cents = value * 100;
    const difference = account_balance - amount_cents * 1e4; // account_balance is in microdollars
    setAmount(amount_cents);
    setRemaining(difference);
  };

  const handleChangeActiveStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handlePreviousActiveStep = () => {
    setActiveStep(0);
  };

  const handleCancel = () => {
    props.handleClose();
    setTimeout(() => {
      setActiveStep(0);
      setAmount(0);
      setDisabled(false);
      setRemaining('');
    }, 100);
  };

  const handleBackDropClicked = () => {
    setTimeout(() => {
      setActiveStep(0);
      setAmount(0);
      setDisabled(false);
      setRemaining('');
    }, 100);
  };

  const handleSubmitTransfer = () => {
    setDisabled(true);
    dispatch(transfer(amount, 'paypal'))
      .then((res) => {
        if (res.status !== 202) {
          setDisabled(false);
          handleCancel();
        }
      })
      .catch(() => {
        handleCancel();
      });
  };

  const resetAuthModal = () => {
    dispatch({
      type: RESET_TWO_FACTOR_AUTH,
    });
    handleCancel();
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PayPalTransferForm
            handleAmount={handleAmount}
            handleChangeActiveStep={handleChangeActiveStep}
            handleClose={handleCancel}
          />
        );
      case 1:
        return (
          <PayPalConfirm
            amount={amount}
            remaining={remaining}
            handleClose={handleCancel}
            handleSubmitTransfer={handleSubmitTransfer}
            handlePreviousActiveStep={handlePreviousActiveStep}
            disabled={disabled}
          />
        );
      case 2:
        return <PayPalSuccess />;
      case 3:
        return (
          <PayPalError
            handleClose={handleCancel}
            handlePreviousActiveStep={handlePreviousActiveStep}
          />
        );
      default:
        return (
          <PayPalTransferForm
            handleAmount={handleAmount}
            handleChangeActiveStep={handleChangeActiveStep}
            handleClose={handleCancel}
          />
        );
    }
  };

  return (
    <Dialog
      maxWidth={'xs'}
      classes={{ paper: classes.root }}
      open={props.open}
      onClose={props.handleClose}
      onBackdropClick={() => handleBackDropClicked()}
    >
      <DialogContent>
        <Typography className={classes.title} color="primary" variant="h4">
          {t('ecowallet.paypal.payPal')}{' '}
          <span className={classes.bold}>{t('ecowallet.paypal.transfer')}</span>
        </Typography>
        {!paypal_email ? (
          <PayPalSignup handleClose={props.handleClose} />
        ) : (
          <React.Fragment>
            {processing ? (
              <Box
                className={classes.spinner}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <CircularProgress color="secondary" />
                <Box mt={3}>
                  <Typography align="center" color="primary">
                    {t('ecowallet.paypal.processingTransfer')}
                  </Typography>
                  <Typography align="center" color="primary">
                    {t('ecowallet.paypal.doNotCloseOrRefresh')}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <TwoStepAuthModal
        withdrawal
        open={twoFactorAuth.required}
        close={resetAuthModal}
      />
    </Dialog>
  );
};

export default PayPalTransfer;

PayPalTransfer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
